import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import StickyDropdown from 'features/common/StickyDropdown';
import { selectPermissions, selectFolderContainers, selectIsAdmin } from 'common/selectors';
import SidebarItem from '.';
import { updateFolderSeq } from '../redux/updateFolderSeq';
import ItemTitle from './ItemTitle';
import equal from 'react-fast-compare';

const mapping = {
  trialbooks: 'trialbooks',
  teamBundles: 'team-bundles',
  privateBundles: 'private-bundles',
};

type DropdownProps = {
  folder: any;
  type: any;
  to: any;
  icon: any;
  className: string;
  buttonActions: any;
  zIndex: number;
  opened: boolean;
  hide?: any;
  code: 'trialbooks' | 'teamBundles' | 'privateBundles';
  isLocked: boolean;
};

const Dropdown = ({
  folder,
  type,
  to,
  className,
  buttonActions,
  zIndex = 0,
  hide,
  opened,
  icon,
  code,
  isLocked,
}: DropdownProps) => {
  const dispatch = useDispatch();
  const containers = useSelector(selectFolderContainers) as any;
  const isSystemAdmin = useSelector(selectIsAdmin);
  const {
    containers: { canUpdate },
  } = useSelector(selectPermissions) as any;

  const canUpdateContainer = useMemo(() => {
    return (canUpdate && canUpdate(mapping[code])) || isSystemAdmin;
  }, [canUpdate, isSystemAdmin, code]);

  const currentContainer = useMemo(() => {
    return containers.find((item: any) => item.code === code);
  }, [containers, code]);
  const [foldersVal, setFoldersVal] = useState<any>([]);

  const reorder = useCallback((list: any, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }, []);

  const handleDragEnd = useCallback(
    (result: any) => {
      if (!result.destination) return;
      if (result.source.index === result.destination.index) return;

      const updatedBundles = reorder(foldersVal, result.source.index, result.destination.index);
      setFoldersVal(updatedBundles);
      const currentDraggedFolder = folder.folders.find((fdr: any) => fdr.id === result.draggableId);
      dispatch(
        updateFolderSeq({
          folder: currentDraggedFolder,
          containerId: currentContainer.id,
          containerEtag: currentContainer.etag,
          index: result.destination.index,
        }),
      ).catch((err: Error) => {
        if (folder.level === 0 && folder.folders.length > 0 && folder.folders[0].name === 'index') {
          setFoldersVal(folder.folders.slice(1));
        } else setFoldersVal(folder.folders);
        console.error(err);
      });
    },
    [dispatch, folder, currentContainer, foldersVal, reorder],
  );

  useEffect(() => {
    if (foldersVal.length === 0) return;
    if (folder.level === 0 && folder.folders.length > 0 && folder.folders[0].name === 'index') {
      setFoldersVal(folder.folders.slice(1));
    } else setFoldersVal(folder.folders);
  }, [folder, foldersVal.length]);

  const renderSidebarItem = useCallback(
    (prop, key) => (
      <SidebarItem
        key={prop.id}
        folder={prop}
        type={type}
        to={to}
        icon={icon}
        className={className}
        buttonActions={buttonActions}
        zIndex={zIndex + 1}
        code={code}
        isLocked={isLocked}
      />
    ),
    [type, to, icon, className, buttonActions, zIndex, code, isLocked],
  );

  return (
    <StickyDropdown
      title={<ItemTitle folder={folder} icon={icon} buttonActions={buttonActions} />}
      hide={hide}
      opened={opened}
      onToggle={() => {
        if (foldersVal.length === 0) {
          if (folder.level === 0 && folder.folders[0]?.name === 'index') {
            setFoldersVal(folder.folders.slice(1));
          } else setFoldersVal(folder.folders);
        }
      }}
    >
      {canUpdateContainer && !folder.agreed ? (
        <>
          {folder.level === 0 && folder.folders[0]?.name === 'index' && (
            <SidebarItem
              key={0}
              folder={folder.folders[0]}
              type={type}
              to={to}
              icon={icon}
              className={className}
              buttonActions={buttonActions}
              zIndex={zIndex + 1}
              code={code}
              isLocked={isLocked}
            />
          )}
          <div>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable
                droppableId={`${folder.id}-list`}
                type={`${folder.id}-level-${folder.level}`}
              >
                {providedDrop => (
                  <div ref={providedDrop.innerRef} {...providedDrop.droppableProps}>
                    {foldersVal.map((prop: any, key: any) => (
                      <Draggable
                        key={prop.id}
                        draggableId={prop.id}
                        index={key}
                        isDragDisabled={isLocked}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              ...(snapshot.isDragging && {
                                position: 'static',
                                backgroundColor: 'rgba(255, 255, 255, 0.23)',
                              }),
                            }}
                          >
                            {renderSidebarItem(prop, key)}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {providedDrop.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </>
      ) : (
        folder.folders.map((prop: any, key: any) => renderSidebarItem(prop, key))
      )}
    </StickyDropdown>
  );
};

export default React.memo(Dropdown, equal);
