import { selectParams } from 'common/selectors';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Dropdown from './Dropdown';
import Item from './Item';
import equal from 'react-fast-compare';

type SideBarItemProps = {
  folder: any;
  type: any;
  to: any;
  icon: any;
  className: string;
  buttonActions: any;
  zIndex: number;
  code: 'teamBundles' | 'privateBundles' | 'trialbooks';
  isLocked?: boolean;
};

const DropdownItem = React.memo((props: any) => {
  const { folder, folder1, folder2, folder3, folder4 } = useSelector(selectParams) as any;
  const {
    folder: { folders, code },
    to,
    isLocked,
  } = props;

  const opened = useMemo(() => {
    return !!folders.find((x: any) =>
      [folder, folder1, folder2, folder3, folder4].includes(x.code),
    );
  }, [folders, folder, folder1, folder2, folder3, folder4]);

  return <Dropdown {...props} to={`${to}/${code}`} opened={opened} isLocked={isLocked} />;
}, equal);

export default React.memo(
  (props: SideBarItemProps) => {
    const {
      folder: { folders, code },
      to,
      zIndex = 0,
      isLocked,
    } = props;
    return (
      <div
        className="case-sidebar-item"
        style={{
          zIndex,
        }}
      >
        {folders && folders.length > 0 ? (
          <DropdownItem {...props} isLocked={isLocked} />
        ) : (
          <Item {...props} to={`${to}/${code}`} />
        )}
      </div>
    );
  },
  (prevProps, nextProps) => {
    return equal(prevProps, nextProps);
  },
);
