import React, { Component, createRef, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import T from 'i18n';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from '@mui/material/Button';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from './redux/actions';
import * as actionsV from 'features/viewing/redux/actions';
import saveAs from 'save-as';
import Split from 'react-split';
import classnames from 'classnames';
import {
  selectCurrentFolder,
  selectPermissions,
  selectCurrentFolderAgreed,
  selectCaseDataRoomSettings,
  selectParams,
  selectCaseShortName,
  selectCaseClaimNo,
  selectChangeDocId,
  selectAutoSetDoDate,
  selectCurrentUserConfidentialityRing,
  selectConfidentialityRing,
  selectCurrentCaseTeam,
  selectFolders,
} from '../../common/selectors';
import Spinner from 'features/common/Spinner';
import TextForm from 'features/common/TextForm';
import ToggleForm from 'features/common/ToggleForm';
import DateForm from 'features/common/DateForm';
import SelectForm from 'features/common/SelectForm';
import { Modal } from 'features/common/MaterialBasedComponents';
import {
  confidentialityRingCategoryLabel,
  documentModalActions,
  suggestedRecipientsMenuOptions,
} from './enums';
import { FileDetails } from './FileDetails';
import {
  Collapsible,
  FileDropZone,
  InfoRow,
  MultiSelectForm,
  SelectConfidentialityRing,
  Table,
} from 'features/common';
import { complexValuesToOptions, findIndexOfDeepNestedFolder } from 'utils/arrays';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Collapse,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Radio,
  Select,
  TextField,
} from '@mui/material';
import history from 'common/history';
import { Card } from 'react-bootstrap';
import { bytesToMegaBytes } from 'utils/formating';
import FilterProvider from './TableCase/FilterProvider';
import { usePrevious } from 'utils/hooks';
import FileViewer from './FileViewer';
import { useUploadConfidentialityRingFiles } from './redux/uploadConfidentialityRingFiles';
import equal from 'react-fast-compare';
import { AddCircleOutline, ExpandLess, ExpandMore } from '@mui/icons-material';
import { unassignDocumentMultiple } from './redux/unassignDocumentMultiple';
import { InfoRowBoolean } from 'features/common/InfoRow';
import { isMobile } from 'utils';
import DateInput from 'features/common/FormControls/DateInput';
import TimeInput from 'features/common/FormControls/TimeInput';

const MoveDocument = ({ control, errors, canUnselect, autofocus, readOnly, currentFolderType }) => {
  const state = useSelector(state => state);
  const [isTrialbundle, setIsTrialBundle] = useState(false);
  const {
    folders: { canAddDocuments },
  } = selectPermissions(state);
  const teamBundles = (state.case.folders.teamBundles || []).filter(folder =>
    canAddDocuments(folder),
  );
  const privateBundles = (state.case.folders.privateBundles || []).filter(folder =>
    canAddDocuments(folder),
  );
  const bundles = (state.case.folders.bundles || []).filter(folder => canAddDocuments(folder));
  const trialbooks = (state.case.folders.trialbooks || []).filter(folder =>
    canAddDocuments(folder),
  );
  const courtBundles = (state.case.folders.courtBundles || []).filter(folder =>
    canAddDocuments(folder),
  );

  const checkIsTrialBundle = (folders, id) => {
    setIsTrialBundle(false);
    const index = findIndexOfDeepNestedFolder(folders, { id });
    setIsTrialBundle(index !== -1);
  };

  const getDefaultValue = trialbooks => {
    if (trialbooks && trialbooks.length > 0) {
      const firstTrialbook = trialbooks[0] || {};
      if (
        firstTrialbook.name
          .toLowerCase()
          .startsWith(T.translate('case.defaultNameForMergedTrialbooks')) &&
        currentFolderType === 'trialbook'
      )
        return firstTrialbook.id || null;
    }
    return null;
  };

  const getBooks = books =>
    books.map(book => {
      if (book.folders && book.folders.length > 0) {
        return {
          label: book.name,
          options: getBooks(book.folders),
        };
      } else {
        return book;
      }
    });

  const options = [
    trialbooks.length > 0 && {
      label: 'Trialbooks',
      options: getBooks(trialbooks),
    },
    courtBundles.length > 0 && {
      label: 'Court bundles',
      options: getBooks(courtBundles),
    },
    teamBundles.length > 0 && {
      label: 'Team Bundles',
      options: getBooks(teamBundles),
    },
    privateBundles.length > 0 && {
      label: 'Private Bundles',
      options: getBooks(privateBundles),
    },
    bundles.length > 0 && {
      label: 'Bundles',
      options: getBooks(bundles),
    },
  ].filter(Boolean);

  return (
    <Form
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      <Form.Group>
        <SelectForm
          label={
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginRight: '1rem',
              }}
            >
              <span>{T.translate('case.folderToMoveTo')}</span>
              {isTrialbundle && <span>{T.translate('case.moveToTriabundleWarning')}</span>}
            </div>
          }
          fullWidthLabel={isTrialbundle}
          placeholder={T.translate('case.folderPlaceholder')}
          name="folder"
          defaultValue={getDefaultValue(trialbooks)}
          valueKey="id"
          labelKey="name"
          options={options}
          control={control}
          rules={{ required: !canUnselect }}
          onChange={val => checkIsTrialBundle(trialbooks, val)}
          isClearable={canUnselect}
          readOnly={readOnly}
          errors={errors}
          autofocus={autofocus}
          menuPosition="fixed"
        />
      </Form.Group>
    </Form>
  );
};

const ModalCopyRemove = ({ show, handleClose, handleSave, action, selectedRows }) => {
  const dispatch = useDispatch();
  const currentFolder = useSelector(selectCurrentFolder);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  return (
    <Modal
      show={show}
      title={T.translate(
        action === documentModalActions.copy
          ? 'case.copyDocumentToFolder'
          : 'case.removeDocumentFromFolder',
      )}
      handleClose={handleClose}
      handleSave={
        action === documentModalActions.copy
          ? handleSubmit(({ folder: folderId }) => {
              handleSave(folderId, action, selectedRows);
              handleClose();
            })
          : () => {
              currentFolder && currentFolder.id
                ? dispatch(unassignDocumentMultiple(selectedRows))
                : handleSave(currentFolder.id, action, selectedRows);
              handleClose();
            }
      }
      saveTitle={T.translate(
        action === documentModalActions.copy ? 'generic.copy' : 'generic.remove',
      )}
    >
      {action === documentModalActions.copy ? (
        <MoveDocument control={control} errors={errors} autofocus={show} />
      ) : (
        <div>
          {T.translate('case.removeDocumentsformFolder', {
            documents: selectedRows.map(row => row.name).join(', '),
            folder: currentFolder.name || 'all documents',
          })}
        </div>
      )}
    </Modal>
  );
};

const DateFormLabel = ({ isAutoSetDocDate, dates, trigger, setValue }) => {
  const [dateType, setDateType] = useState('');
  const handleChange = e => {
    setDateType(e.target.value);
    setValue('docDate', e.target.value.split(' ')[0] || '');
    setValue('docTime', e.target.value.split(' ')[1] || '');
    trigger(['docDate', 'docTime']);
  };

  return (
    isAutoSetDocDate &&
    dates.length > 0 && (
      <div>
        <span>{T.translate('case.autoSetDocDate')} </span>
        <Select
          value={dateType}
          label="Age"
          onChange={handleChange}
          variant="standard"
          autoWidth
          size="small"
          sx={{
            '&::before': { borderBottom: 'none' },
            '&::after': { borderBottom: 'none' },
          }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {dates.length > 0 &&
            dates.map((dt, index) => (
              <MenuItem key={index} value={dt.date}>
                {dt.name + ' - ' + dt.date}
              </MenuItem>
            ))}
        </Select>
      </div>
    )
  );
};

const AuthorRecipientLabel = ({
  isAuthor,
  data,
  setValue,
  setSelectedVal,
  value,
  setRecipientsList,
}) => {
  const customSuggestedRecipientsOptions = [
    {
      value: suggestedRecipientsMenuOptions.addAll,
      label: T.translate('case.addAllSuggestedRecipients'),
    },
  ];
  const handleChange = e => {
    if (isAuthor) {
      setValue('author', e.target.value);
      setSelectedVal(e.target.value);
    } else setRecipientsList(e.target.value);
  };

  return (
    <div>
      <span>{T.translate(isAuthor ? 'case.suggestedAuthors' : 'case.suggestedRecipients')} </span>
      <Select
        value={value}
        onChange={handleChange}
        variant="standard"
        autoWidth
        size="small"
        sx={{
          '&::before': { borderBottom: 'none' },
          '&::after': { borderBottom: 'none' },
        }}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {data.length > 0 &&
          data.map((dt, index) => (
            <MenuItem key={index} value={dt} disabled={!isAuthor}>
              {dt}
            </MenuItem>
          ))}
        {data.length > 0 &&
          !isAuthor &&
          customSuggestedRecipientsOptions.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
      </Select>
    </div>
  );
};

const SubjectLabel = ({ data, setValue, setSelectedVal, value }) => {
  const handleChange = e => {
    setValue('subject', e.target.value);
    setSelectedVal(e.target.value);
  };

  return (
    <div>
      <span>{T.translate('case.suggestedSubject')} </span>
      <Select
        value={value}
        onChange={handleChange}
        variant="standard"
        autoWidth
        size="small"
        sx={{
          '&::before': { borderBottom: 'none' },
          '&::after': { borderBottom: 'none' },
          maxWidth: '150px',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              maxWidth: isMobile ? '70vw' : '30vw',
              wordWrap: 'break-word',
            },
          },
        }}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem
          value={data}
          sx={{
            maxWidth: isMobile ? '70vw' : '30vw',
            whiteSpace: 'normal',
            wordWrap: 'break-word',
          }}
        >
          {data}
        </MenuItem>
      </Select>
    </div>
  );
};

const ModalUpdateMeta = ({
  show,
  handleClose,
  handleSave,
  action,
  selectedRows,
  isAllDocuments,
  currentGridList,
}) => {
  const dispatch = useDispatch();

  const { file: alreadyOpenedFile } = useSelector(selectParams);
  const [file, setFile] = useState(selectedRows[selectedRows.length - 1] || {});
  const prevFile = usePrevious(file);
  const [loading, setLoading] = useState(false);
  const [navigationBtnClicked, setNavigationBtnClicked] = useState(false);
  const [currentSelection, setCurrentSelection] = useState(null);

  const [authorVal, setAuthorVal] = useState(file.author || '');
  const [recipientVal, setRecipientVal] = useState(file.recipient || '');
  const [recipientListVal, setRecipientListVal] = useState(file.recipientsList || []);
  const [newRecipientVal, setNewRecipientVal] = useState('');
  const prevRecipientListVal = usePrevious(recipientListVal);
  const [fileNameVal, setFileNameVal] = useState(file.name || file.originalName || '');
  const [showPreview, setShowPreview] = useState(false);
  const [disableActionBtns, setDisableActionBtns] = useState({
    disableNext: false,
    disablePrev: false,
  });
  const [openEntities, setOpenEntities] = useState(false);

  const {
    documents: { canUpdatePublicData, canUpdateTeamData },
  } = useSelector(selectPermissions);
  const isAutoSetDocDate = useSelector(selectAutoSetDoDate);
  const canChangeDocId = useSelector(selectChangeDocId);
  const isTrialBookAgreed = useSelector(selectCurrentFolderAgreed);
  const currentFolder = useSelector(selectCurrentFolder);
  const totalDocuments = currentGridList.length;
  const { cost, ...entitiesCollection } = (file && file.entities) || null;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
    control,
    trigger,
  } = useForm({
    mode: 'onBlur',
    defaultValues: file.docDate
      ? { docDate: file.docDate.split(' ')[0] || '', docTime: file.docDate.split(' ')[1] || '' }
      : { docDate: '', docTime: '' },
    reValidateMode: 'onBlur',
  });

  const selectNewDocument = fileId => {
    currentGridList.forEach(doc => {
      if (doc.id === fileId) doc.selected = true;
      else doc.selected = false;
    });
  };

  const handleUserKeyPress = useCallback(
    e => {
      if (disableActionBtns.disablePrev && disableActionBtns.disableNext) return;
      setNavigationBtnClicked(false);
      if ((e.ctrlKey || e.metaKey) && e.code === 'ArrowRight') {
        setCurrentSelection(e.target.name);
        const nextBtn = document.getElementById('updateFileNextButton');
        nextBtn.click();
        setNavigationBtnClicked(true);
      } else if ((e.ctrlKey || e.metaKey) && e.code === 'ArrowLeft') {
        setCurrentSelection(e.target.name);
        const prevBtn = document.getElementById('updateFilePrevButton');
        prevBtn.click();
        setNavigationBtnClicked(true);
      }
    },
    [disableActionBtns],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  useEffect(() => {
    if (alreadyOpenedFile) {
      if (alreadyOpenedFile !== file.id)
        history.push(history.location.pathname.replace(/\/files.*/, ``) + `/files/${file.id}`);
    } else window.location.hash = file.id;
  }, [alreadyOpenedFile, file]);

  useEffect(() => {
    if (file.id !== prevFile.id) {
      setLoading(true);
      dispatch(actions.fetchFileDetails({ fileId: file.id, folderId: currentFolder.id })).then(
        res => {
          reset(
            file.docDate
              ? {
                  docDate: file.docDate.split(' ')[0] || '',
                  docTime: file.docDate.split(' ')[1] || '',
                }
              : { docDate: '', docTime: '' },
          );
          setFile(res);

          setFileNameVal(res.name || res.originalName || '');
          setAuthorVal(res.author || '');
          setRecipientVal(res.recipient || '');
          setRecipientListVal(res.recipientsList || []);
          setLoading(false);
          const currentFileIdx = currentGridList.findIndex(doc => doc.id === file.id);
          setDisableActionBtns(
            currentFileIdx === 0
              ? { disableNext: false, disablePrev: true }
              : currentFileIdx === currentGridList.length - 1
              ? { disableNext: true, disablePrev: false }
              : { disableNext: false, disablePrev: false },
          );
        },
      );
    }
  }, [file, prevFile, dispatch, currentFolder, currentGridList, reset]);

  const prevClicked = data => {
    const currentFileIdx = currentGridList.findIndex(doc => doc.id === file.id);
    if (currentFileIdx > 0) {
      setLoading(true);
      setDisableActionBtns({ disableNext: true, disablePrev: true });
      saveFileData(data, currentGridList[currentFileIdx - 1].id);
      reset({});
      setFile(currentGridList[currentFileIdx - 1]);
      setValue('date', '');
      setValue('time', '');
    }
  };

  const nextClicked = data => {
    const currentFileIdx = currentGridList.findIndex(doc => doc.id === file.id);
    if (currentFileIdx < totalDocuments - 1) {
      setLoading(true);
      setDisableActionBtns({ disableNext: true, disablePrev: true });
      saveFileData(data, currentGridList[currentFileIdx + 1].id);
      reset({});
      setFile(currentGridList[currentFileIdx + 1]);
    }
  };

  const saveFileData = (data, newDocId) => {
    const isDocTime = !!data.docTime;
    const cleanData = {
      ...Object.entries(data).reduce(
        (a, [k, v]) =>
          (v === '' && !file[k]) || (isDocTime && k === 'docTime')
            ? a
            : isDocTime && k === 'docDate'
            ? { ...a, [k]: v + ' ' + data['docTime'] }
            : { ...a, [k]: v },
        {},
      ),
      ...{ etag: file.etag },
    };
    if (equal({ ...file, ...cleanData }, file) && newDocId) selectNewDocument(newDocId);
    else handleSave(null, action, [file], cleanData);
  };

  const isValidDate = val => {
    if (val) return moment(val, 'DD/MM/YYYY', true).isValid();
    else return true;
  };

  const isValidTime = val => {
    if (val) return moment(val, 'HH:mm:ss', true).isValid();
    else return true;
  };

  const areAllGroupsEmpty = groups => {
    return Object.values(groups).every(group => group.length === 0);
  };

  const handleAddRecipient = () => {
    if (newRecipientVal) {
      setRecipientListVal([...recipientListVal, newRecipientVal]);
      setNewRecipientVal('');
    }
  };

  const setRecipientsList = suggestedRecipientsOption => {
    if (suggestedRecipientsOption === suggestedRecipientsMenuOptions.addAll) {
      const newRecipients = [
        ...new Set([...recipientListVal, ...(file.suggestedRecipients || [])]),
      ];
      setLoading(true);
      setRecipientListVal(newRecipients);
      setValue('recipientsList', newRecipients);
      setTimeout(() => setLoading(false), 0);
    } else {
      setLoading(true);
      setRecipientListVal([]);
      setValue('recipientsList', []);
      setTimeout(() => setLoading(false), 0);
    }
    setRecipientVal(suggestedRecipientsOption);
  };

  return (
    <Modal
      show={show}
      title={T.translate('case.updateMetadata') + ' - ' + file.id}
      wide={showPreview}
      handleClose={() => {
        handleClose();
        setNavigationBtnClicked(false);
        setCurrentSelection(null);
      }}
      handleSave={handleSubmit(data => {
        saveFileData(data);
        handleClose();
        setNavigationBtnClicked(false);
        setCurrentSelection(null);
      })}
      saveTitle={T.translate('generic.update')}
      buttons={
        <>
          <Button
            type="button"
            id="updateFilePrevButton"
            onClick={handleSubmit(data => prevClicked(data))}
            variant="contained"
            color="primary"
            disabled={disableActionBtns.disablePrev}
          >
            {T.translate('generic.previous')}
          </Button>
          <Button
            type="button"
            id="updateFileNextButton"
            onClick={handleSubmit(data => nextClicked(data))}
            variant="contained"
            color="primary"
            disabled={disableActionBtns.disableNext}
          >
            {T.translate('generic.next')}
          </Button>
        </>
      }
      hintContent={
        <>
          <span>
            <b>{T.translate('generic.shortcuts')}</b>
          </span>
          <Divider />
          <span>{T.translate('common.shortcutPrevText')}</span>
          <br />
          <span>{T.translate('common.shortcutNextText')}</span>
          <br />
          <span>{T.translate('common.shortcutSaveText')}</span>
        </>
      }
      draggableDialog
      fullHeight={showPreview}
    >
      {loading ? (
        <Spinner />
      ) : (
        <Split
          sizes={showPreview ? [45, 55] : [100, 0]}
          minSize={showPreview ? 100 : 0}
          expandToMin={false}
          gutterSize={10}
          gutterAlign="center"
          snapOffset={30}
          dragInterval={1}
          direction="horizontal"
          cursor="col-resize"
          className={classnames({ 'no-gutter': !showPreview }, 'case-table-and-viewer')}
        >
          <Form
            onSubmit={e => {
              e.preventDefault();
            }}
            style={{ ...(showPreview && { marginRight: '0.5rem' }) }}
          >
            <Form.Group style={{ margin: '0.5rem' }}>
              {canUpdatePublicData(file) && (
                <>
                  <Row style={{ marginBottom: 0 }}>
                    <Col>
                      {file.suggestedSubject && (
                        <SubjectLabel
                          isAuthor={false}
                          data={file.suggestedSubject}
                          setValue={setValue}
                          setSelectedVal={setFileNameVal}
                          value={fileNameVal}
                        />
                      )}
                    </Col>
                    <Col />
                  </Row>
                  <Row>
                    <Col>
                      {!(canChangeDocId && isAllDocuments) && (
                        <Button
                          sx={{ float: 'right', p: 0 }}
                          variant="text"
                          onClick={() => {
                            setLoading(true);
                            setShowPreview(!showPreview);
                            setTimeout(() => {
                              setLoading(false);
                            }, 0);
                            setShowPreview(!showPreview);
                          }}
                        >
                          {showPreview ? 'Hide' : 'Preview'}
                        </Button>
                      )}
                      <TextForm
                        {...register('name', {
                          required: true,
                          onChange: e => setFileNameVal(e.target.value),
                        })}
                        label={T.translate('case.systemName')}
                        placeholder={T.translate('case.namePlaceholder')}
                        value={fileNameVal}
                        autofocus={
                          !currentSelection || (navigationBtnClicked && currentSelection === 'name')
                        }
                        errors={errors}
                      />
                    </Col>
                    {canChangeDocId && isAllDocuments && (
                      <Col>
                        <Button
                          sx={{ float: 'right', p: 0 }}
                          variant="text"
                          onClick={() => {
                            setLoading(true);
                            setShowPreview(!showPreview);
                            setTimeout(() => {
                              setLoading(false);
                            }, 0);
                            setShowPreview(!showPreview);
                          }}
                        >
                          {showPreview ? 'Hide' : 'Preview'}
                        </Button>
                        <TextForm
                          {...register('id', { required: true })}
                          label={T.translate('case.documentId')}
                          placeholder={T.translate('case.documentIdPlaceholder')}
                          defaultValue={file.id}
                          errors={errors}
                        />
                      </Col>
                    )}
                  </Row>
                  {isTrialBookAgreed && (
                    <Row>
                      <Col>
                        <TextForm
                          {...register('tab', {
                            validate: { isValidNumber: val => val >= 0 },
                          })}
                          label={T.translate('case.tab')}
                          placeholder={T.translate('case.tabPlaceholder')}
                          defaultValue={file.tab}
                          readOnly={file.agreed}
                          errors={errors}
                        />
                      </Col>
                      {currentFolder && currentFolder.globalPaging ? (
                        <Col>
                          <TextForm
                            {...register('startPage', {
                              validate: { isValidNumber: val => val >= 0 },
                            })}
                            label={T.translate('case.startPage')}
                            type="number"
                            placeholder={T.translate('case.startPagePlaceholder')}
                            defaultValue={file.startPage}
                            readOnly={file.agreed}
                            errors={errors}
                          />
                        </Col>
                      ) : (
                        <Col />
                      )}
                    </Row>
                  )}
                  <Row style={{ marginBottom: 0 }}>
                    <Col>
                      <DateFormLabel
                        isAutoSetDocDate={isAutoSetDocDate}
                        dates={file.docDates}
                        trigger={trigger}
                        setValue={setValue}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Controller
                        name={'docDate'}
                        control={control}
                        rules={{
                          validate: {
                            isValidDate: val => isValidDate(val),
                          },
                          required: !!getValues('time') && T.translate('case.dateRequiredMsg'),
                        }}
                        render={({ field }) => (
                          <DateInput
                            {...field}
                            label={T.translate('case.dateWithFormat')}
                            placeholder={T.translate('case.datePlaceholder')}
                            errors={errors}
                            autofocus={navigationBtnClicked && currentSelection === 'docDate'}
                          />
                        )}
                      />
                    </Col>
                    <Col>
                      <Controller
                        name={'docTime'}
                        control={control}
                        rules={{
                          validate: {
                            isValidTime: val => isValidTime(val),
                          },
                        }}
                        render={({ field }) => (
                          <TimeInput
                            {...field}
                            label={T.translate('case.time')}
                            placeholder={T.translate('case.timePlaceholder')}
                            errors={errors}
                            autofocus={navigationBtnClicked && currentSelection === 'docTime'}
                          />
                        )}
                      />
                    </Col>
                    <Col>
                      <TextForm
                        {...register('altDate')}
                        label={T.translate('case.alternativeDate')}
                        placeholder={T.translate('case.alternativeDatePlaceholder')}
                        defaultValue={file.altDate}
                        autofocus={navigationBtnClicked && currentSelection === 'altDate'}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <TextForm
                        {...register('discoveryId')}
                        label={T.translate('case.discoveryId')}
                        placeholder={T.translate('case.discoveryIdPlaceholder')}
                        defaultValue={file.discoveryId}
                        autofocus={navigationBtnClicked && currentSelection === 'discoveryId'}
                      />
                    </Col>
                    <Col>
                      <TextForm
                        {...register('externalId')}
                        label={T.translate('case.externalId')}
                        placeholder={T.translate('case.externalIdPlaceholder')}
                        defaultValue={file.externalId}
                        autofocus={navigationBtnClicked && currentSelection === 'externalId'}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: 0 }}>
                    <Col>
                      {file.suggestedAuthors && file.suggestedAuthors.length > 0 && (
                        <AuthorRecipientLabel
                          isAuthor={true}
                          data={file.suggestedAuthors}
                          setValue={setValue}
                          setSelectedVal={setAuthorVal}
                          value={authorVal}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <TextForm
                        {...register('author', { onChange: e => setAuthorVal(e.target.value) })}
                        label={T.translate('case.author')}
                        placeholder={T.translate('case.authorPlaceholder')}
                        value={authorVal}
                        autofocus={navigationBtnClicked && currentSelection === 'author'}
                      />
                    </Col>
                    <Col />
                  </Row>
                  <Row style={{ marginBottom: 0 }}>
                    <Col>
                      {file.suggestedRecipients && file.suggestedRecipients.length > 0 && (
                        <AuthorRecipientLabel
                          isAuthor={false}
                          data={file.suggestedRecipients}
                          setValue={setValue}
                          setSelectedVal={setRecipientVal}
                          value={recipientVal}
                          setRecipientsList={setRecipientsList}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          marginBottom: '0.5rem',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <span>{T.translate('case.recipient')}</span>
                        <TextField
                          variant="standard"
                          size="small"
                          autoComplete="off"
                          placeholder={T.translate('case.newRecipientPlaceholder')}
                          value={newRecipientVal}
                          onChange={e => setNewRecipientVal(e.target.value)}
                          onKeyDown={e => {
                            if (e.key === 'Enter' && newRecipientVal.length > 0) {
                              e.preventDefault();
                              e.stopPropagation();
                              handleAddRecipient();
                            }
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handleAddRecipient}
                                  edge="end"
                                  disabled={!newRecipientVal}
                                >
                                  {
                                    <AddCircleOutline
                                      color={!newRecipientVal ? 'disabled' : 'primary'}
                                    />
                                  }
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                      <MultiSelectForm
                        name="recipientsList"
                        defaultValue={recipientListVal}
                        options={recipientListVal.map(val => ({
                          label: val,
                          value: val,
                        }))}
                        control={control}
                        error={errors}
                        autofocus={recipientListVal.length !== prevRecipientListVal?.length}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <TextForm
                        {...register('description')}
                        label={T.translate('case.description')}
                        multiline
                        placeholder={T.translate('case.descriptionPlaceholder')}
                        defaultValue={file.description}
                        autofocus={navigationBtnClicked && currentSelection === 'description'}
                      />
                    </Col>
                  </Row>
                </>
              )}
              {canUpdateTeamData(file) && (
                <Row>
                  <Col>
                    <TextForm
                      {...register('notes')}
                      multiline
                      label={`${T.translate('case.notes')} (${T.translate('case.privateToTeam')})`}
                      placeholder={T.translate('case.notesPlaceholder')}
                      defaultValue={file.notes}
                      autofocus={navigationBtnClicked && currentSelection === 'notes'}
                    />
                  </Col>
                </Row>
              )}
              {entitiesCollection && !areAllGroupsEmpty(entitiesCollection) && (
                <>
                  <Row>
                    <Col style={{ display: 'flex', alignItems: 'center' }}>
                      <span style={{ width: '100%' }}>{T.translate('generic.entities')}</span>
                      <IconButton onClick={() => setOpenEntities(!openEntities)}>
                        {openEntities ? <ExpandLess /> : <ExpandMore />}
                      </IconButton>
                    </Col>
                  </Row>
                  <Collapse in={openEntities} timeout="auto" unmountOnExit>
                    {Object.entries(entitiesCollection).map(
                      ([key, value]) =>
                        value &&
                        value.length > 0 && (
                          <Row key={key}>
                            <Col>
                              <MultiSelectForm
                                label={key}
                                name={key}
                                defaultValue={value}
                                options={value.map(val => ({
                                  label: val,
                                  value: val,
                                  isFixed: true,
                                }))}
                                readOnly
                                control={control}
                                error={errors}
                              />
                            </Col>
                          </Row>
                        ),
                    )}
                  </Collapse>
                </>
              )}
            </Form.Group>
          </Form>
          {showPreview && (
            <div>
              <FileViewer fileId={file.id} isPreviewMode />
            </div>
          )}
        </Split>
      )}
    </Modal>
  );
};

export const ModalMerge = ({ show, handleClose, selectedRows, folder, containers }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const dispatch = useDispatch();
  const [mergingInProgress, setMergingInProgress] = useState(false);
  useEffect(() => {
    if (show) {
      reset();
    }
  }, [selectedRows, folder, show, reset]);
  const currentFolder = useSelector(selectCurrentFolder);
  const dataRoomSettings = useSelector(selectCaseDataRoomSettings);
  const caseShortName = useSelector(selectCaseShortName);
  const caseClaimNo = useSelector(selectCaseClaimNo);
  const isConfidentialityRing = useSelector(selectConfidentialityRing);
  const currrentUserCR = useSelector(selectCurrentUserConfidentialityRing);
  const isInnerRing = isConfidentialityRing && currrentUserCR === 'innerRing';
  const defaultBundleTitleString =
    caseShortName || caseClaimNo
      ? ` (${caseClaimNo && `${caseClaimNo}, `}${caseShortName && caseShortName})`
      : '';
  const [showHyperlinkToggle, setShowHyperlinkToggle] = useState(false);
  const [generateIndexPage, setGenerateIndexPage] = useState(false);
  const [globalPagePrefix, setGlobalPagePrefix] = useState(true);
  const [globalPagePrefixVal, setGlobalPagePrefixVal] = useState(
    (folder && folder.globalPagePrefix) || '',
  );
  const [responseMsg, setResponseMsg] = useState('');
  const dataRoomColumns = dataRoomSettings.columns || [];
  const {
    labelBetween,
    labelVersus,
    labelClaimant,
    labelDefendant,
    indexPageJurisdiction,
    indexPageClaimant,
    indexPageDefendant,
    indexPageColumnsToDisplay,
    indexPageTableDateFormat,
  } = dataRoomSettings.mergeFilesOptions || {};
  const folderToMerge = folder && folder.id;
  const defaultIndexPageColumn =
    indexPageColumnsToDisplay ||
    [
      'trialviewId',
      'title',
      dataRoomColumns.includes('externalId') && 'externalId',
      dataRoomColumns.includes('docDate') && 'docDate',
    ].filter(Boolean);
  const [showIndexPageDateFormat, setShowIndexPageDateFormat] = useState(
    dataRoomColumns.includes('docDate') && defaultIndexPageColumn.includes('docDate'),
  );
  const defaultIndexPageTableDateFormat = indexPageTableDateFormat || 'dd/MM/yyyy';
  const defaultFileVariant = ['innerRing'];
  const simpleValuesToOptions = options =>
    options.map(value => ({ label: moment().format(value.FE), value: value.BE }));
  const indexPageColumnsOptions = complexValuesToOptions(
    [
      { value: 'trialviewId', isFixed: false },
      { value: 'title', isFixed: false },
      { value: 'externalId', isFixed: false },
      { value: 'discoveryId', isFixed: false },
      { value: 'docDate', isFixed: false },
    ],
    'generic',
  );
  const fileVariantsOptions = complexValuesToOptions(
    [
      { value: 'innerRing', isFixed: false },
      { value: 'outerRing', isFixed: false },
      { value: 'noRing', isFixed: false },
    ],
    'generic',
  );
  const indexPageTableColumnsOptions = simpleValuesToOptions([
    { BE: 'dd/MM/yyyy HH:mm', FE: 'DD/MM/YYYY HH:mm' },
    { BE: 'dd/MM/yyyy', FE: 'DD/MM/YYYY' },
    { BE: 'dd.MM.yyyy', FE: 'DD.MM.YYYY' },
    { BE: 'dd MMM yyy', FE: 'DD MMM YYYY' },
  ]);
  const onColumnChange = val => {
    if (val && val.includes('docDate')) setShowIndexPageDateFormat(true);
    else setShowIndexPageDateFormat(false);
  };

  return (
    <Modal
      show={show}
      title={selectedRows ? T.translate('case.merge') : T.translate('case.mergeTrialbook')}
      handleClose={handleClose}
      handleSave={handleSubmit(props => {
        setMergingInProgress(true);
        const {
          folder: folderId,
          name: mergedFileName,
          mergeFileVariants,
          generateIndexPage = false,
          generateIndexPageOnly = false,
          generateBookMarks = false,
          generateLocalPaging = false,
          generateGlobalPaging = false,
          coreBundleUseTrialBundlePaging = false,
          useDoubleSidedPrinting = false,
          burnCrossrefsFirst = false,
          addHyperlinkToCrossRefOnMerge = false,
          globalPagingPrefix = '',
          indexPageTableBorder,
          indexPageClaimNo,
          indexPageJurisdiction,
          indexPageClaimant,
          indexPageDefendant,
          indexPageBundleTitle,
          columnsToDisplay,
          indexPageDateFormat,
        } = props;
        dispatch(
          actions.mergeDocuments({
            mergedFileName,
            mergeFileVariants,
            generateIndexPage,
            generateIndexPageOnly,
            generateBookMarks,
            generateLocalPaging,
            generateGlobalPaging,
            globalPagingPrefix,
            ...(selectedRows && {
              filesToMerge: selectedRows.map(({ id, name }) => ({ id, name })),
            }),
            folderId,
            labelBetween,
            labelVersus,
            labelClaimant,
            labelDefendant,
            indexPageTableBorder,
            indexPageClaimNo,
            indexPageJurisdiction,
            indexPageClaimant,
            indexPageDefendant,
            indexPageBundleTitle,
            columnsToDisplay,
            indexPageDateFormat,
            folderToMerge,
            coreBundleUseTrialBundlePaging,
            useDoubleSidedPrinting,
            burnCrossrefsFirst,
            addHyperlinkToCrossRefOnMerge,
          }),
        )
          .then(res => {
            setMergingInProgress(false);
            if (res && res.resMerge && res.resMerge.asyncMerge) {
              setResponseMsg(T.translate('case.mergeAsyncMsg'));
            } else {
              handleClose();
            }
          })
          .catch(res => {
            setMergingInProgress(false);
            if (res && res.resMerge && res.resMerge.asyncMerge) {
              setResponseMsg(T.translate('case.mergeAsyncMsg'));
            } else {
              handleClose();
            }
          });
      })}
      saveTitle={
        mergingInProgress ? <Spinner style={{ height: '1.5rem' }} /> : T.translate('generic.create')
      }
      disableSave={mergingInProgress || !!responseMsg}
    >
      <PerfectScrollbar options={{ suppressScrollX: true }} style={{ maxHeight: '45rem' }}>
        {responseMsg ? (
          <span>{responseMsg}</span>
        ) : (
          <Form.Group style={{ marginBottom: '3rem' }}>
            <div style={{ marginBottom: '1rem' }}>
              {selectedRows
                ? T.translate('case.mergeTitle', {
                    numberOfFiles: selectedRows.length,
                    folderName: currentFolder.name || T.translate('case.allDocuments'),
                  })
                : T.translate('case.mergeTrialbookMsg', { folderName: folder.name })}
            </div>
            <Row>
              <Col>
                <TextForm
                  {...register('name')}
                  label={T.translate('case.mergeFileName')}
                  placeholder={T.translate('case.mergeFileNamePlaceholder')}
                  defaultValue={`${(selectedRows
                    ? currentFolder.name || T.translate('case.allDocuments')
                    : folder.name || T.translate('case.allDocuments')) +
                    defaultBundleTitleString} (${moment().format('DD/MM/YYYY')})`}
                  readOnly={mergingInProgress}
                  autofocus
                />
              </Col>
            </Row>
            {isInnerRing && (
              <Row>
                <Col>
                  <MultiSelectForm
                    label={T.translate('case.selectFileVariants')}
                    name="mergeFileVariants"
                    defaultValue={defaultFileVariant}
                    options={fileVariantsOptions}
                    onChange={onColumnChange}
                    control={control}
                    errors={errors}
                    rules={{ required: true }}
                  />
                </Col>
                <Col />
              </Row>
            )}
            <Row>
              <Col>
                <ToggleForm
                  {...register('generateIndexPage', {
                    onChange: () => setGenerateIndexPage(!generateIndexPage),
                  })}
                  defaultValue={generateIndexPage}
                  label={T.translate(`case.generateIndexPage`)}
                  readOnly={mergingInProgress}
                />
              </Col>
              <Col />
              <Col />
            </Row>
            {generateIndexPage && (
              <div
                style={{
                  border: '1px solid #E3E3E3',
                  borderRadius: '0.5rem',
                  padding: '1rem',
                  marginBottom: '0.5rem',
                }}
              >
                <Row>
                  <Col>
                    <ToggleForm
                      {...register('indexPageTableBorder')}
                      defaultValue={true}
                      label={T.translate(`case.indexPageTableBorder`)}
                      readOnly={mergingInProgress}
                    />
                  </Col>
                  <Col />
                  <Col />
                </Row>
                <Row>
                  <Col>
                    <TextForm
                      {...register('indexPageClaimNo')}
                      as="textarea"
                      label={T.translate('case.indexPageClaimNo')}
                      placeholder={T.translate('case.indexPageClaimNoPlaceholder')}
                      defaultValue={caseClaimNo}
                      readOnly={mergingInProgress}
                    />
                  </Col>
                  <Col>
                    <TextForm
                      {...register('indexPageJurisdiction')}
                      as="textarea"
                      label={T.translate('case.indexPageJurisdiction')}
                      placeholder={T.translate('case.indexPageJurisdictionPlaceholder')}
                      defaultValue={indexPageJurisdiction}
                      readOnly={mergingInProgress}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextForm
                      {...register('indexPageClaimant')}
                      as="textarea"
                      label={labelClaimant || T.translate('case.indexPageClaimant')}
                      placeholder={T.translate('case.indexPageClaimantPlaceholder')}
                      defaultValue={indexPageClaimant}
                      readOnly={mergingInProgress}
                    />
                  </Col>
                  <Col>
                    <TextForm
                      {...register('indexPageDefendant')}
                      as="textarea"
                      label={labelDefendant || T.translate('case.indexPageDefendant')}
                      placeholder={T.translate('case.indexPageDefendantPlaceholder')}
                      defaultValue={indexPageDefendant}
                      readOnly={mergingInProgress}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextForm
                      {...register('indexPageBundleTitle')}
                      label={T.translate('case.indexPageBundleTitle')}
                      placeholder={T.translate('case.indexPageBundleTitlePlaceholder')}
                      defaultValue={
                        selectedRows
                          ? currentFolder.name || T.translate('case.allDocuments')
                          : folder.name
                      }
                      readOnly={mergingInProgress}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <MultiSelectForm
                      label={T.translate('case.heading4')}
                      name="columnsToDisplay"
                      defaultValue={defaultIndexPageColumn}
                      options={indexPageColumnsOptions}
                      onChange={onColumnChange}
                      control={control}
                      error={errors}
                    />
                  </Col>
                  {showIndexPageDateFormat && (
                    <Col>
                      <SelectForm
                        label={T.translate('case.IndexPageTableDateFormat')}
                        name="indexPageDateFormat"
                        defaultValue={defaultIndexPageTableDateFormat}
                        options={indexPageTableColumnsOptions}
                        control={control}
                      />
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col>
                    <ToggleForm
                      {...register('generateIndexPageOnly')}
                      defaultValue={false}
                      label={T.translate(`case.generateIndexPageOnly`)}
                      readOnly={mergingInProgress}
                    />
                  </Col>
                  <Col />
                  <Col />
                </Row>
              </div>
            )}
            <Row>
              <Col>
                <ToggleForm
                  {...register('generateBookMarks')}
                  defaultValue={true}
                  label={T.translate(`case.generateBookMarks`)}
                  readOnly={mergingInProgress}
                />
              </Col>
              <Col />
              <Col />
            </Row>
            <Row>
              <Col>
                <ToggleForm
                  {...register('generateLocalPaging')}
                  defaultValue={true}
                  label={T.translate(`case.generateLocalPaging`)}
                  readOnly={mergingInProgress}
                />
              </Col>
              <Col />
              <Col />
            </Row>
            <Row>
              <Col>
                <ToggleForm
                  {...register('generateGlobalPaging', { onChange: setGlobalPagePrefix })}
                  defaultValue={globalPagePrefix}
                  label={T.translate(`case.generateGlobalPaging`)}
                  readOnly={mergingInProgress}
                />
              </Col>
              <Col />
              <Col />
            </Row>
            <Row>
              <Col>
                <ToggleForm
                  {...register('coreBundleUseTrialBundlePaging')}
                  defaultValue={false}
                  label={T.translate(`case.coreBundleUseTrialBundlePaging`)}
                  readOnly={mergingInProgress}
                />
              </Col>
              <Col />
              <Col />
            </Row>
            <Row>
              <Col>
                <ToggleForm
                  {...register('useDoubleSidedPrinting')}
                  defaultValue={false}
                  label={T.translate(`case.useDoubleSidedPrinting`)}
                  readOnly={mergingInProgress}
                />
              </Col>
              <Col />
              <Col />
            </Row>
            <Row>
              <Col>
                <ToggleForm
                  {...register('burnCrossrefsFirst', {
                    onChange: () => setShowHyperlinkToggle(!showHyperlinkToggle),
                  })}
                  defaultValue={false}
                  label={T.translate(`case.includeCrossRef`)}
                  readOnly={mergingInProgress}
                />
              </Col>
              <Col />
              <Col />
            </Row>
            {showHyperlinkToggle && (
              <Row>
                <Col>
                  <ToggleForm
                    {...register('addHyperlinkToCrossRefOnMerge')}
                    defaultValue={false}
                    label={T.translate(`case.convertCrossRefToExternalHyperlink`)}
                    readOnly={mergingInProgress}
                  />
                </Col>
                <Col />
                <Col />
              </Row>
            )}
            {globalPagePrefix && (
              <Row>
                <Col>
                  <TextForm
                    {...register('globalPagingPrefix', {
                      onChange: e => {
                        e.target.value.length > 1
                          ? setGlobalPagePrefixVal(e.target.value.slice(0, 1))
                          : setGlobalPagePrefixVal(e.target.value);
                      },
                    })}
                    label={T.translate('case.globalPagePrefix')}
                    placeholder={T.translate('case.globalPagePrefixPlaceholder')}
                    value={globalPagePrefixVal}
                    readOnly={mergingInProgress}
                  />
                </Col>
                <Col />
              </Row>
            )}
            <MoveDocument
              control={control}
              errors={errors}
              canUnselect={true}
              readOnly={mergingInProgress}
              currentFolderType={folder ? folder.type : currentFolder.type || null}
            />
          </Form.Group>
        )}
      </PerfectScrollbar>
    </Modal>
  );
};

export const DownloadModal = ({ show, handleClose, selectedRows, folder, containers }) => {
  const fileVersionOptions = [
    { name: 'case.downloadFolderModal.fileVersionOptions.originalFile', version: 'original' },
    {
      name: 'case.downloadFolderModal.fileVersionOptions.originalAsPDF',
      version: 'pdf',
    },
    // {
    //   name: 'case.downloadFolderModal.fileVersionOptions.originalAsPDFOCR',
    //   version: 'originalAsPDFOCR',
    // },
    {
      name: 'case.downloadFolderModal.fileVersionOptions.mostRecentPDF',
      version: 'recent-pdf',
    },
    {
      name: 'case.downloadFolderModal.fileVersionOptions.mostRecentOCR',
      version: 'recent-ocr',
    },
  ];

  const fileNameOptions = [
    {
      name: 'case.downloadFolderModal.fileNameOptions.originalFileName',
      fileName: 'original',
    },
    { name: 'case.downloadFolderModal.fileNameOptions.fileName', fileName: 'folderSpecificName' },
    { name: 'case.downloadFolderModal.fileNameOptions.trialviewID', fileName: 'trialviewId' },
  ];
  // TODO: Temporary for testing while we prepare backend
  const disableOptions = false;
  const { register, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      source: fileVersionOptions[0].version,
      filenameSelector: fileNameOptions[0].fileName,
      includeMerge: false,
    },
  });
  const dispatch = useDispatch();

  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const [fileVersion, setFileVersion] = useState(fileVersionOptions[0]);
  const [fileName, setFileName] = useState(fileNameOptions[0]);
  const [includeMergeFiles, setIncludeMergeFiles] = useState(false);

  useEffect(() => {
    if (show) {
      reset();
    }
  }, [selectedRows, folder, show, reset]);

  const handleFileVersionChange = e => {
    setFileVersion(e.target.value);
    setValue('source', e.target.value); // Update RHF's state
  };

  const handleFileNameChange = e => {
    setFileName(e.target.value);
    setValue('filenameSelector', e.target.value); // Update RHF's state
  };

  const handleDownload = data => {
    setDownloadInProgress(true);
    dispatch(
      actions.downloadFolder({
        folderId: folder?.id,
        ...data,
      }),
    )
      .then(response => {
        const link = document.createElement('a');
        link.href = response;
        link.download = `${folder?.name}.zip`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .then(() => {
        setDownloadInProgress(false);
        handleClose();
      });
  };

  return (
    <Modal
      show={show}
      title={T.translate('case.downloadFolderModal.title')}
      handleClose={handleClose}
      handleSave={handleSubmit(handleDownload)}
      saveTitle={
        downloadInProgress ? (
          <Spinner
            style={{
              height: '1.5rem',
            }}
          />
        ) : (
          T.translate('generic.download')
        )
      }
      disableSave={downloadInProgress}
    >
      <Form.Group
        style={{
          marginBottom: '3rem',
        }}
      >
        <div
          style={{
            marginBottom: '1rem',
          }}
        >
          {T.translate('case.downloadFolderModal.downloadMsg', { folderName: folder?.name })}
        </div>

        {/* File Version Dropdown */}
        <Row>
          <Col>
            <Form.Label>{T.translate('common.fileVersion')}</Form.Label>
            <Form.Control
              {...register('source')}
              as="select"
              value={fileVersion}
              onChange={handleFileVersionChange}
              readOnly={downloadInProgress}
              disabled={disableOptions}
            >
              {fileVersionOptions.map(value => (
                <option key={value.version} value={value.version}>
                  {T.translate(value.name)}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Row>

        {/* File Name Dropdown */}
        <Row>
          <Col>
            <Form.Label>{T.translate('common.fileName')}</Form.Label>
            <Form.Control
              {...register('filenameSelector')}
              disabled={disableOptions}
              as="select"
              value={fileName}
              onChange={handleFileNameChange}
              readOnly={downloadInProgress}
            >
              {fileNameOptions.map(value => (
                <option key={value.name} value={value.fileName}>
                  {T.translate(value.name)}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Row>
        {/* Include Merge Files Toggle */}
        <Row>
          <Col>
            <ToggleForm
              {...register('includeMerge')}
              defaultValue={includeMergeFiles}
              label={T.translate('case.downloadFolderModal.includeMergeFiles')}
              onChange={newValue => {
                setIncludeMergeFiles(newValue);
                setValue('includeMerge', newValue.target.checked);
              }}
              readOnly={downloadInProgress}
              disabled={disableOptions}
            />
          </Col>
        </Row>
      </Form.Group>
    </Modal>
  );
};
const ModalReplace = ({ show, handleClose, handleSave, action, selectedRows }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const isConfidentialityRing = useSelector(selectConfidentialityRing);
  const currentFolder = useSelector(selectCurrentFolder);
  const [loading, setLoading] = useState(false);
  const [fileVariants, setFileVariants] = useState(null);
  const file = selectedRows[0] || {};
  const { originalName, name } = file;

  useEffect(() => {
    if (isConfidentialityRing && !fileVariants) {
      setLoading(true);
      dispatch(actions.fetchFileRings({ fileId: selectedRows[0].id }))
        .then(res => setFileVariants(res))
        .finally(() => setLoading(false));
    }
  }, [selectedRows, dispatch, fileVariants, isConfidentialityRing]);

  return (
    <Modal
      show={show}
      title={T.translate('generic.replaceFile')}
      handleClose={handleClose}
      handleSave={handleSubmit(data => {
        setLoading(true);
        handleSave(currentFolder.id, action, selectedRows, data, handleClose, setLoading);
      })}
      saveTitle={T.translate('generic.replace')}
      disableSave={loading}
    >
      {loading ? (
        <Spinner />
      ) : isConfidentialityRing && fileVariants && fileVariants.length > 1 ? (
        <div>{T.translate('case.replaceModalMultipleFileVariantsMsg')}</div>
      ) : (
        <>
          <div>
            {T.translate('case.replaceModalMsg', {
              documentName: name || originalName,
              folder: currentFolder.name || 'all documents',
            })}
          </div>
          <br />
          <Form
            onSubmit={e => {
              e.preventDefault();
            }}
          >
            <Form.Group>
              <Row>
                <Col>
                  <TextForm
                    {...register('newFile', { required: true })}
                    type="file"
                    errors={errors}
                  />
                </Col>
                <Col />
                <Col />
              </Row>
            </Form.Group>
          </Form>
        </>
      )}
    </Modal>
  );
};

const ModalGenerateHyperlinks = ({ show, handleClose, handleSave, action, selectedRows }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      show={show}
      title={T.translate('generic.generateHyperlinks')}
      handleClose={() => {
        handleClose();
      }}
      handleSave={handleSubmit(data => {
        setLoading(true);
        handleSave(null, action, selectedRows, data, handleClose, setLoading);
      })}
      disableSave={loading}
      saveTitle={T.translate('generic.submit')}
    >
      {loading ? (
        <Spinner />
      ) : (
        <Form
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <Form.Group>
            <Row>
              <Col>
                <TextForm
                  {...register('matchUsingPrefix', { required: true })}
                  label={T.translate('case.matchUsingPrefix')}
                  placeholder={T.translate('case.matchUsingPrefixPlaceholder')}
                  errors={errors}
                  autofocus
                />
              </Col>
              <Col />
              <Col />
            </Row>
            <Row>
              <Col>
                <ToggleForm
                  {...register('overwriteExistingHyperlinks')}
                  defaultValue={true}
                  label={T.translate(`case.overwriteExistingHyperlinks`)}
                />
              </Col>
              <Col />
              <Col />
            </Row>
          </Form.Group>
        </Form>
      )}
    </Modal>
  );
};

const ModalGenerateTranscriptbasedHyperlinks = ({
  show,
  handleClose,
  handleSave,
  action,
  selectedRows,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      show={show}
      title={T.translate('generic.generateHyperlinks')}
      handleClose={() => handleClose()}
      handleSave={handleSubmit(data => {
        setLoading(true);
        handleSave(null, action, selectedRows, data, handleClose, setLoading);
      })}
      disableSave={loading}
      saveTitle={T.translate('generic.submit')}
    >
      {loading ? (
        <Spinner />
      ) : (
        <Form
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <Form.Group>
            <Row>
              <Col>
                <Form.Label>{T.translate('case.transcriptDate')}</Form.Label>
                <DateForm
                  {...register('transcriptDate', { required: true })}
                  placeholder={T.translate('case.datePlaceholder')}
                  errors={errors}
                  autoFocus
                />
              </Col>
              <Col />
              <Col />
            </Row>
            <Row>
              <Col>
                <ToggleForm
                  {...register('overwriteExistingHyperlinks')}
                  defaultValue={true}
                  label={T.translate(`case.overwriteExistingHyperlinks`)}
                />
              </Col>
              <Col />
              <Col />
            </Row>
          </Form.Group>
        </Form>
      )}
    </Modal>
  );
};

const FindAndReplaceHyperlinks = ({ show, handleClose, handleSave, action, selectedRows }) => {
  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  return (
    <Modal
      show={show}
      title={T.translate('generic.findAndReplaceHyperlinks')}
      handleClose={() => handleClose()}
      handleSave={handleSubmit(data => {
        setLoading(true);
        handleSave(null, action, selectedRows, data, handleClose, setLoading);
      })}
      disableSave={loading}
      saveTitle={T.translate('generic.submit')}
    >
      {loading ? (
        <Spinner />
      ) : (
        <Form
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <Form.Group>
            <Row>
              <Col>
                <Form.Label>{T.translate('case.findAndReplaceHyperlinksMsg')}</Form.Label>
              </Col>
            </Row>
            <Row>
              <Col>
                <ToggleForm
                  {...register('overwriteExistingHyperlinks')}
                  defaultValue={true}
                  label={T.translate(`case.overwriteExistingHyperlinks`)}
                />
              </Col>
              <Col />
              <Col />
            </Row>
          </Form.Group>
        </Form>
      )}
    </Modal>
  );
};

const MoreInfoModal = ({ show, handleClose, selectedRows, filter }) => {
  const dispatch = useDispatch();
  const folders = useSelector(selectFolders);
  const foldersList = Object.values(folders).reduce((acc, list) => {
    return [...acc, ...list];
  }, []);
  const [mergeDetails, setMergeDetails] = useState(null);
  const {
    mergedFileName = '',
    generateIndexPage,
    generateBookMarks,
    generateLocalPaging,
    generateGlobalPaging,
    coreBundleUseTrialBundlePaging,
    useDoubleSidedPrinting,
    burnCrossrefsFirst,
    globalPagingPrefix,
    folderToMerge,
  } = mergeDetails || {};
  const mergedFolderName = folderToMerge
    ? foldersList.find(folder => folder.id === folderToMerge)?.name
    : '';

  useEffect(() => {
    if (selectedRows[0].source === 'merge') {
      dispatch(actions.fetchDocumentMergeDetails({ fileId: selectedRows[0].id })).then(res =>
        setMergeDetails(res),
      );
    }
  }, [selectedRows, dispatch]);

  return (
    <>
      <Modal
        show={show}
        title={T.translate('generic.more')}
        handleClose={() => {
          handleClose();
        }}
      >
        <FilterProvider value={{ filter }}>
          <FileDetails row={selectedRows[0]} columns={[]} DefaultShowMoreInfo={true} />
          {mergeDetails && (
            <div style={{ margin: '1rem' }}>
              <Collapsible title={T.translate('case.mergeDetails')}>
                <InfoRow field={mergedFileName} title={T.translate('case.mergedFileName')} />
                <InfoRowBoolean
                  field={generateIndexPage}
                  title={T.translate(`case.generateIndexPage`)}
                />
                <InfoRowBoolean
                  field={generateBookMarks}
                  title={T.translate(`case.generateBookMarks`)}
                />
                <InfoRowBoolean
                  field={generateLocalPaging}
                  title={T.translate(`case.generateLocalPaging`)}
                />
                <InfoRowBoolean
                  field={generateGlobalPaging}
                  title={T.translate(`case.generateGlobalPaging`)}
                />
                <InfoRowBoolean
                  field={coreBundleUseTrialBundlePaging}
                  title={T.translate(`case.coreBundleUseTrialBundlePaging`)}
                />
                <InfoRowBoolean
                  field={useDoubleSidedPrinting}
                  title={T.translate(`case.useDoubleSidedPrinting`)}
                />
                <InfoRowBoolean
                  field={burnCrossrefsFirst}
                  title={T.translate(`case.includeCrossRef`)}
                />

                <InfoRow field={globalPagingPrefix} title={T.translate(`case.globalPagePrefix`)} />
                <InfoRow field={mergedFolderName} title={T.translate(`case.mergedFolderName`)} />
              </Collapsible>
            </div>
          )}
        </FilterProvider>
      </Modal>
    </>
  );
};

const UpdateIndexModal = ({ show, handleClose, selectedRows, currentGridList }) => {
  const dispatch = useDispatch();
  const [newIndex, setNewIndex] = useState(parseInt(selectedRows[0]?.tab));
  const [error, setError] = useState(false);
  const totalDocuments = currentGridList.length;
  const [file] = useState(selectedRows[0]);
  const [positionMessage, setPositionMessage] = useState(selectedRows[0]?.name);
  const maxIndex = currentGridList?.length;
  const indexHandler = val => {
    setError(false);
    if (val < 1 || isNaN(val)) {
      setError(true);
      setNewIndex(null);
    } else {
      setNewIndex(val);
    }
  };

  const handleSave = () => {
    if (newIndex < 1 || isNaN(newIndex) || newIndex > totalDocuments) {
      setError(true);
    } else {
      dispatch(
        actions.updateDocumentSort({
          index: newIndex - 1,
          ids: [file?.id],
          zeroBasedIndex: selectedRows[0].zeroBasedIndex,
          agreed: selectedRows[0].agreed,
        }),
      );
      handleClose();
    }
  };

  const handleMoveThroughFullList = position => {
    let indexToReposition = 0;
    if (position === 'last') {
      indexToReposition = currentGridList.length - 1;
    }
    const idsOfMultipleSelected = selectedRows.map(f => f.id);

    dispatch(
      actions.updateDocumentSort({
        index: indexToReposition,
        ids: selectedRows?.length > 1 ? idsOfMultipleSelected : [file?.id],
        zeroBasedIndex: file?.zeroBasedIndex,
        agreed: file?.agreed,
      }),
    );

    handleClose();
  };

  const handleSaveMultiple = () => {
    if (newIndex < 1 || isNaN(newIndex) || newIndex > totalDocuments) {
      setError(true);
      return;
    } else {
      const ids = selectedRows.map(f => f.id);
      let addedIndexValue = Number(newIndex - 1) + Number(selectedRows?.length - 1);
      if (addedIndexValue >= maxIndex - 1) {
        handleMoveThroughFullList('last');
      } else {
        dispatch(
          actions.updateDocumentSort({
            index: newIndex > selectedRows[0].tab ? addedIndexValue : newIndex - 1,
            ids,
            zeroBasedIndex: selectedRows[0].zeroBasedIndex,
            agreed: selectedRows[0].agreed,
          }),
        );
      }

      handleClose();
    }
  };

  const renderMultipleFilesContent = () => {
    return (
      <div>
        <p>{T.translate('generic.updateMultipleIndexes.warning')}</p>
        <PerfectScrollbar>
          <ul style={{ height: '150px' }}>
            {selectedRows.map(f => {
              return (
                <li key={f.id} style={{ listStyleType: 'none', left: 0, paddingBottom: 3 }}>
                  {`${f?.tab}: ${f?.name}`}
                </li>
              );
            })}
          </ul>
        </PerfectScrollbar>

        <p>
          {T.translate('generic.updateMultipleIndexes.indexOfFirst', {
            index: selectedRows[0]?.tab,
          })}
        </p>
        <TextField
          fullWidth={true}
          variant="outlined"
          autoComplete="off"
          placeholder={`${T.translate('generic.changePositionModal.placeholder')}`}
          onChange={e => indexHandler(e.target.value)}
          onKeyDown={e => {
            if (e.key === 'Enter' && newIndex > 0 && !error && !isNaN(newIndex)) {
              e.preventDefault();
              e.stopPropagation();
              handleSaveMultiple();
            }
          }}
          value={newIndex}
          autoFocus={true}
          error={error}
        />
        <p className="max-length">
          {T.translate('generic.updateMultipleIndexes.goToIndex', {
            name: positionMessage,
          })}
          <br />
          {`${T.translate('generic.updateIndex.maxValue', {
            index: maxIndex,
          })}`}
        </p>
      </div>
    );
  };

  const renderSingleFileContent = () => (
    <>
      <p>{`${T.translate('generic.changePositionModal.currentIndex')}: ${file?.tab}`}</p>
      <TextField
        fullWidth={true}
        variant="outlined"
        autoComplete="off"
        placeholder={`${T.translate('generic.changePositionModal.placeholder')}`}
        onChange={e => indexHandler(e.target.value)}
        onKeyDown={e => {
          if (
            e.key === 'Enter' &&
            newIndex > 0 &&
            !error &&
            !isNaN(newIndex) &&
            newIndex < selectedRows?.length
          ) {
            e.preventDefault();
            e.stopPropagation();
            handleSave();
          }
        }}
        value={newIndex}
        autoFocus={true}
        error={error}
      />
      <p className="max-length">{`${T.translate('generic.updateIndex.maxValue', {
        index: maxIndex,
      })}`}</p>

      {error && <p style={{ color: 'red' }}>{T.translate('generic.notAValidEntry')}</p>}
    </>
  );

  useEffect(() => {
    if (newIndex <= selectedRows[0]?.tab) {
      setPositionMessage(selectedRows[0]?.name);
    } else {
      setPositionMessage(selectedRows[selectedRows?.length - 1]?.name);
    }
  }, [newIndex, selectedRows]);

  return (
    <Modal
      show={show}
      title={`${T.translate('generic.changePosition')} of ${file?.name}`}
      handleClose={handleClose}
      handleSave={selectedRows?.length > 1 ? handleSaveMultiple : handleSave}
      saveTitle={T.translate('generic.changePosition')}
      disableSave={error}
      fullWidthLabel={true}
      buttons={
        <>
          <Button
            type="button"
            id="firstIndexMove"
            onClick={() => handleMoveThroughFullList('first')}
            variant="contained"
            color="primary"
          >
            {T.translate('generic.updateIndex.moveFirst')}
          </Button>
          <Button
            type="button"
            id="lastIndexMove"
            onClick={() => handleMoveThroughFullList('last')}
            variant="contained"
            color="primary"
          >
            {T.translate('generic.updateIndex.moveLast')}
          </Button>
        </>
      }
    >
      {selectedRows?.length > 1 ? renderMultipleFilesContent() : renderSingleFileContent()}
    </Modal>
  );
};

const fileVersionsTablecolumnSelector = (
  updateFileVersions,
  createTiffAction,
  isAdminLevelPermisisons,
  action,
  setDeleteVersion,
  showDeleteFileVersion,
  lastVersion,
) => {
  return [
    (isAdminLevelPermisisons || action === documentModalActions.download) && {
      id: 'active',
      Header: action === documentModalActions.download ? 'common.selected' : 'common.active',
      Cell: version => (
        <div className="customGrid">
          <span>
            {version.type && version.type === 'dummy' ? (
              ''
            ) : (
              <Radio checked={version.active} onClick={() => updateFileVersions(version.id)} />
            )}
          </span>
        </div>
      ),
      minWidth: '60px',
      width: '10rem',
      sort: false,
    },
    {
      id: 'size',
      Header: 'common.size',
      Cell: version => (
        <div className="customGrid">
          <span>
            {!version.type ? (version.size ? bytesToMegaBytes(version.size) : '0 MB') : ''}
          </span>
        </div>
      ),
      minWidth: '80px',
      width: '15rem',
      sort: false,
    },
    {
      id: 'pageCount',
      Header: 'generic.pages',
      Cell: version => (
        <div className="customGrid">
          <span>{version.pageCount}</span>
        </div>
      ),
      minWidth: '80px',
      width: '10rem',
      sort: false,
    },
    {
      id: 'source',
      Header: 'common.source',
      Cell: version => (
        <div className="customGrid">
          <span>{version.type && version.type === 'dummy' ? '' : version.source}</span>
        </div>
      ),
      minWidth: '150px',
      width: '15rem',
      sort: false,
    },
    {
      id: 'created',
      Header: 'common.created',
      Cell: version => (
        <div className="customGrid">
          <span>
            {version.type && version.type === 'dummy'
              ? ''
              : moment(version.created).format('DD/MM/YYYY HH:mm')}
          </span>
        </div>
      ),
      minWidth: '150px',
      width: `calc(100% - ${(isAdminLevelPermisisons ? 10 : 0) + 55}rem )`,
      sort: false,
    },
    {
      id: 'action',
      Header: 'common.action',
      Cell: version => (
        <div className="customGrid">
          {version.type && version.type === 'dummy' && (
            <Button
              variant="text"
              sx={{ paddingLeft: 0, justifyContent: 'start' }}
              size="small"
              onClick={() => {
                createTiffAction();
              }}
              disabled={
                (version.type &&
                  version.type === 'dummy' &&
                  action !== documentModalActions.fileVersions) ||
                version.size === 0
              }
            >
              {T.translate('generic.createTiff')}
            </Button>
          )}
          {showDeleteFileVersion && lastVersion.id !== version.id && version?.type !== 'dummy' && (
            <Button
              variant="text"
              sx={{ paddingLeft: 0, justifyContent: 'start' }}
              size="small"
              onClick={() => {
                setDeleteVersion(version);
              }}
              disabled={action !== documentModalActions.fileVersions}
            >
              {T.translate('generic.delete')}
            </Button>
          )}
        </div>
      ),
      minWidth: '110px',
      width: '15rem',
      sort: false,
    },
  ].filter(Boolean);
};

export const FileVersions = ({ show, handleClose, selectedRows, action }) => {
  const { register, reset, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const [showHyperlinkToggle, setShowHyperlinkToggle] = useState(false);
  const [fileVersions, setFileVersions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteVersion, setDeleteVersion] = useState(null);
  const { file } = useSelector(selectParams);
  const { documents: { canSeeFileVersions, canRemoveFileVersions } = {} } = useSelector(
    selectPermissions,
  );
  const originalVersionsList = fileVersions.filter(itm => !itm.type && itm.type !== 'dummy');
  const showDeleteFileVersion =
    canRemoveFileVersions &&
    originalVersionsList.length > 1 &&
    action === documentModalActions.fileVersions;
  const lastVersion = originalVersionsList[originalVersionsList.length - 1];
  const isAdminLevelPermisisons = canSeeFileVersions && canSeeFileVersions(selectedRows[0]);
  const isItemLoaded = ({ index }) => !!fileVersions[index];
  const loadMoreItems = () => {};
  const activeVersion = fileVersions.find(itm => itm.active);

  const deleteFileVersion = versionId => {
    setLoading(true);
    dispatch(
      actions.deleteFileVersions({
        fileId: selectedRows[0].id || file,
        versions: [{ id: versionId }],
      }),
    )
      .then(() => {
        const filteredFileVersions = fileVersions.filter(itm => itm.id !== versionId);
        setFileVersions(filteredFileVersions);
      })
      .finally(() => {
        setLoading(false);
        setDeleteVersion(null);
      });
  };

  const createTiffAction = () => {
    dispatch(
      actions.createFileVersion({
        fileId: selectedRows[0].id || file,
        newVersion: { source: 'tiff' },
      }),
    ).then(res => {
      const filteredTiffList = fileVersions.filter(itm => !itm.type && itm.type !== 'dummy');
      const newFileVersions = [...filteredTiffList, res];
      setFileVersions(newFileVersions);
    });
  };
  const downloadActiveFileVersion = data => {
    setLoading(true);
    dispatch(
      actionsV.downloadDocument({
        fileId: selectedRows[0].id || file,
        version: activeVersion.source,
        versionId: activeVersion.id,
        fileType: selectedRows[0].type,
        burnId: data.burnId,
        burnCrossRef: data.burnCrossRef,
        hyperlink: data.hyperlink,
      }),
    ).then(response => {
      return response && saveAs(response.blob, response.fileName || selectedRows[0].id || file);
    });
    setTimeout(() => {
      handleClose();
      setLoading(false);
    }, 500);
  };
  const updateFileVersions = versionId => {
    const updatedFileVersions = fileVersions.map(item => {
      if (item.id === versionId) return { ...item, active: true };
      else return { ...item, active: false };
    });
    if (!equal(fileVersions, updatedFileVersions) && action === documentModalActions.fileVersions)
      dispatch(
        actions.updateFileVersions({
          fileId: selectedRows[0].id || file,
          versions: updatedFileVersions,
        }),
      ).then(res => setFileVersions(res));
    else if (action === documentModalActions.download) setFileVersions(updatedFileVersions);
  };
  const columns = fileVersionsTablecolumnSelector(
    updateFileVersions,
    createTiffAction,
    isAdminLevelPermisisons,
    action,
    setDeleteVersion,
    showDeleteFileVersion,
    lastVersion,
  );

  useEffect(() => {
    dispatch(actions.fetchFileVersions({ fileId: selectedRows[0].id || file }))
      .then(res => {
        const containsTiff = res.find(itm => itm.source === 'tiff');
        if (
          isAdminLevelPermisisons &&
          !containsTiff &&
          action === documentModalActions.fileVersions
        )
          setFileVersions([...res, { type: 'dummy' }]);
        else setFileVersions(res);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [show, selectedRows, dispatch, isAdminLevelPermisisons, file, action]);

  return (
    <>
      <Modal
        show={show}
        title={
          (action === documentModalActions.fileVersions
            ? T.translate('generic.fileVersions')
            : T.translate('generic.download')) + ` - ${selectedRows[0].id || file}`
        }
        handleClose={() => {
          if (loading) setLoading(false);
          setFileVersions([]);
          handleClose();
        }}
        saveTitle={
          action === documentModalActions.download
            ? T.translate('generic.download')
            : action === documentModalActions.fileVersions && deleteVersion
            ? T.translate('generic.delete')
            : null
        }
        handleSave={
          action === documentModalActions.download
            ? handleSubmit(data => downloadActiveFileVersion(data))
            : action === documentModalActions.fileVersions && deleteVersion
            ? handleSubmit(() => deleteFileVersion(deleteVersion.id))
            : null
        }
        disableSave={loading}
      >
        {!loading &&
          !deleteVersion &&
          (fileVersions && fileVersions.length > 0 ? (
            <Form
              onSubmit={e => {
                e.preventDefault();
              }}
            >
              <Form.Group>
                <Card
                  style={{
                    height: '15rem',
                    ...(action === documentModalActions.fileVersions && {
                      margin: '-1.5rem',
                      height: '25rem',
                    }),
                  }}
                >
                  <Table
                    key={Math.random()}
                    data={fileVersions}
                    columns={columns}
                    isItemLoaded={isItemLoaded}
                    loadMoreItems={loadMoreItems}
                    totalDocumentsCount={0}
                    rowHeight={50}
                    readOnly
                  />
                </Card>
                {action === documentModalActions.download && (
                  <>
                    {activeVersion && activeVersion.source !== 'merge' && (
                      <Row style={{ marginTop: '1rem' }}>
                        <Col>
                          <ToggleForm
                            {...register('burnId')}
                            defaultValue={false}
                            label={T.translate(`generic.includeTvId`)}
                          />
                        </Col>
                        <Col />
                        <Col />
                      </Row>
                    )}
                    {activeVersion && activeVersion.crossRefCount > 0 && (
                      <>
                        <Row>
                          <Col>
                            <ToggleForm
                              {...register('burnCrossRef', {
                                onChange: () => {
                                  setShowHyperlinkToggle(!showHyperlinkToggle);
                                  reset({ hyperlink: false });
                                },
                              })}
                              defaultValue={false}
                              label={T.translate(`case.includeCrossRef`)}
                            />
                          </Col>
                          <Col />
                          <Col />
                        </Row>
                        <Row>
                          <Col>
                            <ToggleForm
                              {...register('hyperlink')}
                              defaultValue={false}
                              label={T.translate(`case.convertCrossRefToExternalHyperlink`)}
                              disabled={!showHyperlinkToggle}
                            />
                          </Col>
                          <Col />
                          <Col />
                        </Row>
                      </>
                    )}
                  </>
                )}
              </Form.Group>
            </Form>
          ) : (
            T.translate('generic.noFileVersionsFound')
          ))}
        {loading && <Spinner />}
        {!loading && deleteVersion && (
          <span>{T.translate('case.deleteVersionWarning', { source: deleteVersion.source })}</span>
        )}
      </Modal>
    </>
  );
};

const fileVariantsColumnSelector = (
  handleFileUpload,
  handleChangeRing,
  ringMenuItems,
  handleDeleteRing,
  fileVariants,
  downloadRingDoc,
) => {
  return [
    {
      id: 'ring',
      Header: 'common.ring',
      Cell: variant =>
        variant.type !== 'dummy' && (
          <div className="customGrid">
            {ringMenuItems.length < 2 ? (
              <span>{variant.ring && confidentialityRingCategoryLabel[variant.ring]}</span>
            ) : (
              <SelectConfidentialityRing
                showLabel={false}
                defaultValue={variant.ring}
                onChange={({ target: { value } }) => handleChangeRing({ ...variant, ring: value })}
                disableNoRingMenu={!ringMenuItems.includes('noRing')}
                disableOuterRingMenu={!ringMenuItems.includes('outerRing')}
                disableInnerRingMenu={!ringMenuItems.includes('innerRing')}
              />
            )}
          </div>
        ),
      minWidth: '150px',
      width: '15rem',
      sort: false,
    },
    {
      id: 'pageCount',
      Header: 'common.pageCount',
      Cell: version => (
        <div className="customGrid">
          <span>{version.pageCount}</span>
        </div>
      ),
      minWidth: '110px',
      width: '10rem',
      sort: false,
    },
    {
      id: 'originalFilename',
      Header: 'common.fileName',
      Cell: version => (
        <div className="customGrid">
          <span>{version.originalFilename}</span>
        </div>
      ),
      minWidth: '190px',
      width: '20rem',
      sort: false,
    },
    {
      id: 'created',
      Header: 'common.created',
      Cell: variant => (
        <div className="customGrid">
          <span>
            {variant.type === 'dummy' ? '' : moment(variant.created).format('DD/MM/YYYY HH:mm')}
          </span>
        </div>
      ),
      minWidth: '110px',
      width: `10rem`,
      sort: false,
    },
    {
      id: 'action',
      Header: 'common.action',
      Cell: variant => {
        const dropzoneRef = createRef();
        const originalFileVariants = fileVariants.filter(itm => itm.id);
        return variant.type === 'dummy' ? (
          <div className="customGrid">
            <FileDropZone
              refLink={dropzoneRef}
              showInfo
              confidentialityRing={variant.name}
              onDropCustom={acceptedFiles => handleFileUpload(acceptedFiles, variant.name)}
            >
              <div style={{ display: 'flex', flexDirection: 'column', padding: 0 }}>
                <Button
                  variant="text"
                  sx={{ paddingLeft: 0, justifyContent: 'start' }}
                  size="small"
                  onClick={() => openDialog(dropzoneRef)}
                >
                  {T.translate('generic.uploadRingDocBtnLabel', {
                    name: confidentialityRingCategoryLabel[variant.name],
                  })}
                </Button>
              </div>
            </FileDropZone>
          </div>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column', padding: 0 }}>
            <Button
              variant="text"
              sx={{ paddingLeft: 0, justifyContent: 'start' }}
              size="small"
              onClick={() => downloadRingDoc(variant.ring)}
            >
              {T.translate('generic.download')}
            </Button>
            {fileVariants[0].id && originalFileVariants.length > 1 && (
              <Button
                variant="text"
                sx={{ paddingLeft: 0, justifyContent: 'start' }}
                size="small"
                onClick={() => handleDeleteRing(variant.id)}
              >
                {T.translate('generic.delete')}
              </Button>
            )}
          </div>
        );
      },
      minWidth: '150px',
      width: `calc(100% - 55rem)`,
      sort: false,
    },
  ].filter(Boolean);
};

const openDialog = ref => {
  if (ref.current) {
    ref.current.open();
  }
};

const FileVariants = ({ show, selectedRows, handleClose }) => {
  const dispatch = useDispatch();
  const [fileVariants, setFileVariants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [ringMenuItems, setRingMenuItems] = useState([]);
  const { uploadConfidentialityRingFilesPending } = useUploadConfidentialityRingFiles();
  const isItemLoaded = ({ index }) => !!fileVariants[index];
  const loadMoreItems = () => {};

  const updateFileCR = () => {
    dispatch(
      actions.fetchFileDetails({
        fileId: selectedRows[0].id,
        updateDocuments: 'confidentialityRing',
      }),
    );
  };

  const handleDeleteRing = ringId => {
    setLoading(true);
    dispatch(actions.deleteFileRing({ fileId: selectedRows[0].id, ringId }))
      .then(res => {
        updateFileCR();
        const updatedFileVariants = fileVariants.filter(itm => itm.id && itm.id !== ringId);
        handleFileVariants(updatedFileVariants);
      })
      .catch(() => setLoading(false));
  };

  const downloadRingDoc = ring => {
    setLoading(true);
    dispatch(
      actionsV.downloadDocument({
        fileId: selectedRows[0].id,
        fileType: selectedRows[0].type,
        variant: ring,
      }),
    )
      .then(response => {
        return response && saveAs(response.blob, response.fileName || selectedRows[0].id);
      })
      .finally(() => setLoading(false));
  };

  const handleChangeRing = updatedRing => {
    setLoading(true);
    dispatch(
      actions.updateConfidentialityRing({
        fileId: selectedRows[0].id,
        ring: updatedRing,
      }),
    )
      .then(res => {
        const updatedFileVariants = fileVariants.reduce(
          (acc, itm) => (itm.id ? (itm.id === res.id ? [...acc, res] : [...acc, itm]) : acc),
          [],
        );
        handleFileVariants(updatedFileVariants);
      })
      .catch(() => setLoading(false));
  };

  const handleFileUpload = (files, variantName) => {
    setLoading(true);
    dispatch(
      actions.uploadConfidentialityRingFiles({
        fileId: selectedRows[0].id,
        file: files[0],
        ring: variantName,
      }),
    ).catch(() => setLoading(false));
  };
  const columns = fileVariantsColumnSelector(
    handleFileUpload,
    handleChangeRing,
    ringMenuItems,
    handleDeleteRing,
    fileVariants,
    downloadRingDoc,
  );

  const handleFileVariants = res => {
    const rings = res.map(itm => itm.ring);
    const allRings =
      res.length === 3
        ? res
        : [
            ...res,
            !rings.includes('noRing') && { type: 'dummy', name: 'noRing' },
            !rings.includes('outerRing') && { type: 'dummy', name: 'outerRing' },
            !rings.includes('innerRing') && { type: 'dummy', name: 'innerRing' },
          ].filter(Boolean);
    setFileVariants(allRings);
    setRingMenuItems([...['noRing', 'innerRing', 'outerRing'].filter(itm => !rings.includes(itm))]);
    setLoading(false);
  };

  useEffect(() => {
    if (!uploadConfidentialityRingFilesPending)
      dispatch(actions.fetchFileRings({ fileId: selectedRows[0].id }))
        .then(res => handleFileVariants(res))
        .catch(() => setLoading(false));
  }, [selectedRows, dispatch, uploadConfidentialityRingFilesPending]);
  return (
    <Modal
      show={show}
      title={T.translate('generic.fileVariants') + ` for ${selectedRows[0].id}`}
      handleClose={() => {
        setFileVariants([]);
        handleClose();
      }}
    >
      {!loading ? (
        fileVariants && fileVariants.length > 0 ? (
          <Card style={{ height: '25rem', margin: '-1.5rem' }}>
            <Table
              key={Math.random()}
              data={fileVariants}
              columns={columns}
              isItemLoaded={isItemLoaded}
              loadMoreItems={loadMoreItems}
              totalDocumentsCount={0}
              rowHeight={50}
              hoverableRows={false}
            />
          </Card>
        ) : (
          T.translate('generic.noFileVariantsFound')
        )
      ) : (
        <Spinner />
      )}
    </Modal>
  );
};

const TranscriptNotes = ({ show, handleClose, handleSave, action, selectedRows }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();
  const {
    documents: { createNotesOnBehalfOfOtherUser },
  } = useSelector(selectPermissions);
  const currentCaseTeam = useSelector(selectCurrentCaseTeam);
  const [loading, setLoading] = useState(false);
  const userOptions =
    (createNotesOnBehalfOfOtherUser &&
      currentCaseTeam &&
      currentCaseTeam.users &&
      currentCaseTeam.users.map(user => {
        return { value: user.id, label: user.mail };
      })) ||
    [];

  return (
    <Modal
      show={show}
      title={T.translate('generic.transcriptNotes')}
      handleClose={handleClose}
      handleSave={handleSubmit(data => {
        setLoading(true);
        handleSave(null, action, selectedRows, data, handleClose, setLoading);
      })}
      saveTitle={T.translate('generic.upload')}
      disableSave={loading}
    >
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div style={{ ...(createNotesOnBehalfOfOtherUser && { marginBottom: '1rem' }) }}>
            {T.translate('case.uploadTranscriptMsg', {
              documentName: selectedRows[0].name || selectedRows[0].originalName,
            })}
          </div>
          {createNotesOnBehalfOfOtherUser && (
            <Row>
              <Col>
                <SelectForm
                  label={T.translate('case.onBehalfOfUser')}
                  placeholder={T.translate('case.onBehalfOfUserPlaceholder')}
                  name="onBehalfOfUserId"
                  options={userOptions}
                  control={control}
                  isClearable
                  menuPosition="fixed"
                />
              </Col>
              <Col />
            </Row>
          )}
          <br />
          <Form
            onSubmit={e => {
              e.preventDefault();
            }}
          >
            <Form.Group>
              <Row>
                <Col>
                  <TextForm
                    {...register('newFile', { required: true })}
                    type="file"
                    errors={errors}
                  />
                </Col>
                <Col />
                <Col />
              </Row>
            </Form.Group>
          </Form>
        </>
      )}
    </Modal>
  );
};

export default class DocumentsModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    handleSave: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    action: PropTypes.string.isRequired,
    selectedRows: PropTypes.array.isRequired,
    fileCount: PropTypes.number.isRequired,
  };

  render() {
    if (this.props.action === documentModalActions.merge) return <ModalMerge {...this.props} />;
    if (this.props.action === documentModalActions.updateMeta)
      return <ModalUpdateMeta {...this.props} />;
    if (this.props.action === documentModalActions.replace) return <ModalReplace {...this.props} />;
    if (this.props.action === documentModalActions.generateHyperlinks)
      return <ModalGenerateHyperlinks {...this.props} />;
    if (this.props.action === documentModalActions.generateTranscriptsbasedHyperlinks)
      return <ModalGenerateTranscriptbasedHyperlinks {...this.props} />;
    if (this.props.action === documentModalActions.findAndReplaceHyperlinks)
      return <FindAndReplaceHyperlinks {...this.props} />;
    if (this.props.action === documentModalActions.moreInfo)
      return <MoreInfoModal {...this.props} />;
    if (
      this.props.action === documentModalActions.fileVersions ||
      this.props.action === documentModalActions.download
    )
      return <FileVersions {...this.props} />;
    if (this.props.action === documentModalActions.fileVariants)
      return <FileVariants {...this.props} />;
    if (this.props.action === documentModalActions.uploadTranscriptNotes)
      return <TranscriptNotes {...this.props} />;
    if (this.props.action === documentModalActions.changePosition)
      return <UpdateIndexModal {...this.props} />;
    return <ModalCopyRemove {...this.props} />;
  }
}
