import React, { forwardRef, useReducer, useEffect } from 'react';
import AcusoftViewer from 'features/viewing/AcusoftViewer';
import { fileTypes } from './enums';
import VideoViewer from 'features/viewing/VideoViewer';
import { useSelector } from 'react-redux';
import { selectParams } from 'common/selectors';
import { useFetchFileDetails } from './redux/fetchFileDetails';
import { Spinner } from 'features/common';
import { setCurrentSelectedFile } from './redux/setCurrentSelectedFile';
import { useDispatch } from 'react-redux';

function reducer(state: any, action: { type: any; fileType: any; videoDetails: any }) {
  switch (action.type) {
    case 'updateDetails': {
      return { ...state, fileType: action.fileType, videoDetails: action.videoDetails };
    }
    default:
      return state;
  }
}

type FileViewerProps = {
  fileId: string;
  fileData: any;
  hearingRoomMode: 'public' | 'private';
  removeInput: boolean;
  bundleSpecificFileDetails: any;
  searchHandler: (stuff: any) => void;
  searchFunctionsHandler: (stuff: any) => void;
  enableScroll: boolean;
  fileType: typeof fileTypes;
  isFullScreen: boolean;
  isPreviewMode: boolean;
  currentFileMetaData: any;
};

const Comp = forwardRef(
  (
    {
      fileId,
      fileData,
      hearingRoomMode,
      removeInput,
      bundleSpecificFileDetails,
      searchHandler,
      searchFunctionsHandler,
      enableScroll = !removeInput,
      fileType = fileData && (fileData.type || fileData.fileType),
      isFullScreen,
      isPreviewMode,
      currentFileMetaData,
    }: FileViewerProps,
    ref,
  ) => {
    const dispatchAct = useDispatch();
    const [fileDetails, dispatch] = useReducer(reducer, { fileType, videoDetails: null });
    const { fetchFileDetails } = useFetchFileDetails();

    // MOUNT
    useEffect(() => {
      if (currentFileMetaData && currentFileMetaData.type !== fileTypes.mp4)
        dispatch({ type: 'updateDetails', fileType: currentFileMetaData.type, videoDetails: null });
      else if (
        !fileDetails.fileType ||
        (fileDetails.fileType === fileTypes.mp4 && !fileDetails.videoDetails)
      ) {
        fetchFileDetails({ fileId, firstTrialBookOnly: true }).then(
          (res: {
            type: string;
            location: any;
            subtitlesLocation: any;
            transcript: any;
            docDate: string;
            docsPresented: any;
            subtype: string;
            detectedSpeakers: any;
            recordedSpeakers: any;
          }) => {
            dispatch({
              type: 'updateDetails',
              fileType: res.type,
              videoDetails:
                res.type === fileTypes.mp4
                  ? {
                      id: fileId,
                      videoLocation: res.location,
                      subtitlesLocation: res.subtitlesLocation,
                      transcript: res.transcript,
                      recordingTime:
                        res.docDate && res.docDate.length > 1 && res.docDate.split(' ')[1],
                      docsPresented: res.docsPresented,
                      isRecording: res.subtype === 'recording',
                      detectedSpeakers: res.detectedSpeakers,
                      recordedSpeakers: res.recordedSpeakers,
                    }
                  : null,
            });
            dispatchAct(setCurrentSelectedFile(res));
          },
        );
      }
      // MOUNT
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return !fileDetails.fileType ||
      (fileDetails.fileType === fileTypes.mp4 && !fileDetails.videoDetails) ? (
      <Spinner />
    ) : fileDetails.fileType === fileTypes.mp4 ? (
      <div className="video-content">
        <VideoViewer
          key={`video-viewer-${fileId}`}
          ref={ref}
          hearingRoomMode={hearingRoomMode}
          fileId={fileId}
          removeInput={removeInput}
          videoDetails={fileDetails.videoDetails}
          isFullScreen={isFullScreen}
        />
      </div>
    ) : (
      <AcusoftViewer
        key={`acusoft-viewer-${fileId}`}
        ref={ref}
        hearingRoomMode={hearingRoomMode}
        fileId={fileId}
        fileData={fileData}
        removeInput={removeInput}
        enableScroll={enableScroll}
        bundleSpecificFileDetails={bundleSpecificFileDetails}
        searchHandler={searchHandler}
        searchFunctionsHandler={searchFunctionsHandler}
        isFullScreen={isFullScreen}
        isPreviewMode={isPreviewMode}
      />
    );
  },
);

export default React.memo(
  forwardRef((props: any, ref) => {
    const { file } = useSelector(selectParams) as any;

    const fileId = (props.fileData && props.fileData.fileId) || file;

    return <Comp key={fileId} ref={ref} fileId={fileId} {...props} />;
  }),
);
