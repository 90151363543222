// This is the root reducer of the feature. It is used for:
//   1. Load reducers from each action in the feature and process them one by one.
//      Note that this part of code is mainly maintained by Rekit, you usually don't need to edit them.
//   2. Write cross-topic reducers. If a reducer is not bound to some specific action.
//      Then it could be written here.
// Learn more from the introduction of this approach:
// https://medium.com/@nate_wang/a-new-approach-for-managing-redux-actions-91c26ce8b5da.

import initialState from './initialState';
import { reducer as fetchFoldersReducer } from './fetchFolders';
import { reducer as fetchContainersReducer } from './fetchContainers';
import { reducer as fetchDocumentsReducer } from './fetchDocuments';
import { reducer as fetchDocumentsDocFinderReducer } from './fetchDocumentsDocFinder';
import { reducer as uploadFilesReducer } from './uploadFiles';
import { reducer as assignDocumentsReducer } from './assignDocuments';
import { reducer as unassignDocumentsReducer } from './unassignDocuments';
import { reducer as addFolderReducer } from './addFolder';
import { reducer as deleteFolderReducer } from './deleteFolder';
import { reducer as updateFolderReducer } from './updateFolder';
import { reducer as updateDocumentReducer } from './updateDocument';
import { reducer as updateDocumentSortReducer } from './updateDocumentSort';
import { reducer as mergeDocumentsReducer } from './mergeDocuments';
import { reducer as fetchTemplatesReducer } from './fetchTemplates';
import { reducer as fetchTeamsReducer } from './fetchTeams';
import { reducer as fetchTeamReducer } from './fetchTeam';
import { reducer as updateTeamMemberReducer } from './updateTeamMember';
import { reducer as removeTeamMemberReducer } from './removeTeamMember';
import { reducer as fetchInviteLinkReducer } from './fetchInviteLink';
import { reducer as sendInviteReducer } from './sendInvite';
import { reducer as acceptTeamInviteReducer } from './acceptTeamInvite';
import { reducer as createTeamReducer } from './createTeam';
import { reducer as updateTeamReducer } from './updateTeam';
import { reducer as removeTeamReducer } from './removeTeam';
import { reducer as getTeamActivityLogsReducer } from './getTeamActivityLogs';
import { reducer as getMoreInfoDocumentReducer } from './getMoreInfoDocument';
import { reducer as getMoreInfoFolderReducer } from './getMoreInfoFolder';
import { reducer as getFolderStats } from './getFolderStats';
import { reducer as fetchLayoutsReducer } from './fetchLayouts';
import { reducer as updateLayoutsReducer } from './updateLayouts';
import { reducer as fetchCaseDetailsReducer } from './fetchCaseDetails';
import { reducer as updateCaseReducer } from './updateCase';
import { reducer as updateDocumentMetaReducer } from './updateDocumentMeta';
import { reducer as fetchTsAndCs } from './fetchTsAndCs';
import { reducer as acceptTsAndCs } from './acceptTsAndCs';
import { reducer as fetchFileDetails } from './fetchFileDetails';
import { reducer as replaceFile } from './replaceFile';
import { reducer as generateHyperlinks } from './generateHyperlinks';
import { reducer as fetchRecentFilesReducer } from './fetchRecentFiles';
import { reducer as fetchSearchedFilesReducer } from './fetchSearchedFiles';
import { reducer as fetchUserDetailsReducer } from './fetchUserDetails';
import { reducer as fetchCaseAdminsReducer } from './fetchCaseAdmins';
import { reducer as fetchCaseModeratorsReducer } from './fetchCaseModerators';
import { reducer as addCaseRolesUserReducer } from './addCaseRolesUser';
import { reducer as removeCaseRolesUserReducer } from './removeCaseRolesUser';
import { reducer as fetchCaseRolesReducer } from './fetchCaseRoles';
import { reducer as addSystemTestReducer } from './addSystemTest';
import { reducer as fetchSystemTestReducer } from './fetchSystemTest';
import { reducer as reSendInviteReducer } from './reSendInvite';
import { reducer as fetchLoginHistoryReducer } from './fetchLoginHistory';
import { reducer as fetchFolderReducer } from './fetchFolder';
import { reducer as fetchFolderSpecificFileDetailsReducer } from './fetchFolderSpecificFileDetails';
import { reducer as updateFolderSeqReducer } from './updateFolderSeq';
import { reducer as fetchContainerReducer } from './fetchContainer';
import { reducer as fetchTagsReducer } from './fetchTags';
import { reducer as createTagReducer } from './CreateTag';
import { reducer as deleteTagReducer } from './deleteTag';
import { reducer as updateTagReducer } from './updateTag';
import { reducer as setCurrentSelectedFileReducer } from './setCurrentSelectedFile';
import { reducer as fetchAnnotationsReducer } from './fetchAnnotations';
import { reducer as updateAnnotationsReducer } from './updateAnnotations';
import { reducer as deleteAnnotationsReducer } from './deleteAnnotations';
import { reducer as addRemoveAnnotationsReducer } from './addRemoveAnnotations';
import { reducer as updateAnnotationSortReducer } from './updateAnnotationSort';
import { reducer as setFilterOptionsReducer } from './setFilterOptions';
import { reducer as fetchFileVersionsReducer } from './fetchFileVersions';
import { reducer as updateFileVersionsReducer } from './updateFileVersions';
import { reducer as createFileVersionReducer } from './createFileVersion';
import { reducer as fetchDialInNumbersReducer } from './fetchDialInNumbers';
import { reducer as updateDialInNumbersReducer } from './updateDialInNumbers';
import { reducer as deleteDialInNumberReducer } from './updateDialInNumbers';
import { reducer as fetchHearingRoomLogsReducer } from './fetchHearingRoomLogs';
import { reducer as fetchNotificationsReducer } from './fetchNotifications';
import { reducer as updateNotificationReducer } from './updateNotification';
import { reducer as updateNotificationsReducer } from './updateNotifications';
import { reducer as fetchTagGroupsReducer } from './fetchTagGroups';
import { reducer as assignAnnotationsReducer } from './assignAnnotations';
import { reducer as burnCrossReferencesReducer } from './burnCrossReferences';
import { reducer as fetchFileRingsReducer } from './fetchFileRings';
import { reducer as uploadConfidentialityRingFilesReducer } from './uploadConfidentialityRingFiles';
import { reducer as updateConfidentialityRingReducer } from './updateConfidentialityRing';
import { reducer as deleteFileRingReducer } from './deleteFileRing';
import { reducer as fetchMatchesReducer } from './fetchMatches';
import { reducer as generateAnswersReducer } from './generateAnswers';
import { reducer as reIndexBundleReducer } from './reIndexBundle';
import { reducer as uploadTranscriptNotesReducer } from './uploadTranscriptNotes';
import { reducer as modifyDocumentSortSelectionReducer } from './modifyDocumentSortSelection';
import { reducer as createPageRefReducer } from './createPageRef';
import { reducer as fetchPageRefsReducer } from './fetchPageRefs';
import { reducer as fetchFolderHealthReducer } from './fetchFolderHealth';
import { reducer as fetchSearchHistoryReducer } from './fetchSearchHistory';
import { reducer as fetchSearchHistoryItemReducer } from './fetchSearchHistoryItem';
import { reducer as updateSearchHistoryItemReducer } from './updateSearchHistoryItem';
import { reducer as deleteSearchHistoryItemReducer } from './deleteSearchHistoryItem';
import { reducer as createTimelinesReducer } from './createTimelines';
import { reducer as updateTimelinesReducer } from './updateTimelines';
import { reducer as deleteTimelinesReducer } from './deleteTimelines';
import { reducer as fetchTimelinesReducer } from './fetchTimelines';
import { reducer as unassignDocumentMultipleReducer } from './unassignDocumentMultiple';
import { reducer as assignEventsToTimelineReducer } from './assignEventsToTimeline';
import { reducer as fetchTimelineReducer } from './fetchTimeline';
import { reducer as exportTimelineReducer } from './exportTimeline';
import { reducer as exportFolderReducer } from './exportFolder';
import { reducer as downloadFolderReducer } from './downloadFolder';
import { reducer as deleteFileVersionsReducer } from './deleteFileVersions';
import { reducer as fetchDocumentMergeDetailsReducer } from './fetchDocMergeDetails';
import { reducer as aiToolsFiltersReducer } from './setAiToolsFilters';
import { reducer as showDocFinderBookmarks } from './setShowDocFinderBookmarks';
import { reducer as showDocFinderFilter } from './setShowDocFinderFilter';
import { reducer as createGroupReducer } from './createGroup';
import { reducer as fetchGroupsReducer } from './fetchGroups';
import { reducer as removeGroupReducer } from './removeGroup';

const reducers = [
  fetchFoldersReducer,
  fetchContainersReducer,
  fetchDocumentsReducer,
  fetchDocumentsDocFinderReducer,
  uploadFilesReducer,
  assignDocumentsReducer,
  unassignDocumentsReducer,
  addFolderReducer,
  deleteFolderReducer,
  updateFolderReducer,
  updateDocumentReducer,
  updateDocumentSortReducer,
  mergeDocumentsReducer,
  fetchTemplatesReducer,
  fetchTeamsReducer,
  fetchTeamReducer,
  updateTeamMemberReducer,
  removeTeamMemberReducer,
  createTeamReducer,
  updateTeamReducer,
  removeTeamReducer,
  fetchInviteLinkReducer,
  sendInviteReducer,
  acceptTeamInviteReducer,
  getTeamActivityLogsReducer,
  getMoreInfoDocumentReducer,
  getMoreInfoFolderReducer,
  getFolderStats,
  fetchLayoutsReducer,
  updateLayoutsReducer,
  fetchCaseDetailsReducer,
  updateCaseReducer,
  updateDocumentMetaReducer,
  fetchTsAndCs,
  acceptTsAndCs,
  fetchFileDetails,
  replaceFile,
  generateHyperlinks,
  fetchRecentFilesReducer,
  fetchSearchedFilesReducer,
  fetchUserDetailsReducer,
  fetchCaseAdminsReducer,
  fetchCaseModeratorsReducer,
  addCaseRolesUserReducer,
  removeCaseRolesUserReducer,
  fetchCaseRolesReducer,
  addSystemTestReducer,
  fetchSystemTestReducer,
  reSendInviteReducer,
  fetchLoginHistoryReducer,
  fetchFolderReducer,
  fetchFolderSpecificFileDetailsReducer,
  updateFolderSeqReducer,
  fetchContainerReducer,
  fetchTagsReducer,
  createTagReducer,
  deleteTagReducer,
  updateTagReducer,
  setCurrentSelectedFileReducer,
  fetchAnnotationsReducer,
  updateAnnotationsReducer,
  deleteAnnotationsReducer,
  addRemoveAnnotationsReducer,
  updateAnnotationSortReducer,
  setFilterOptionsReducer,
  fetchFileVersionsReducer,
  updateFileVersionsReducer,
  createFileVersionReducer,
  fetchDialInNumbersReducer,
  updateDialInNumbersReducer,
  deleteDialInNumberReducer,
  fetchHearingRoomLogsReducer,
  fetchNotificationsReducer,
  fetchTagGroupsReducer,
  assignAnnotationsReducer,
  updateNotificationReducer,
  updateNotificationsReducer,
  burnCrossReferencesReducer,
  fetchFileRingsReducer,
  uploadConfidentialityRingFilesReducer,
  updateConfidentialityRingReducer,
  deleteFileRingReducer,
  fetchMatchesReducer,
  generateAnswersReducer,
  reIndexBundleReducer,
  uploadTranscriptNotesReducer,
  modifyDocumentSortSelectionReducer,
  createPageRefReducer,
  fetchPageRefsReducer,
  fetchFolderHealthReducer,
  fetchSearchHistoryReducer,
  fetchSearchHistoryItemReducer,
  updateSearchHistoryItemReducer,
  deleteSearchHistoryItemReducer,
  createTimelinesReducer,
  updateTimelinesReducer,
  deleteTimelinesReducer,
  fetchTimelinesReducer,
  unassignDocumentMultipleReducer,
  assignEventsToTimelineReducer,
  fetchTimelineReducer,
  exportTimelineReducer,
  exportFolderReducer,
  downloadFolderReducer,
  deleteFileVersionsReducer,
  fetchDocumentMergeDetailsReducer,
  aiToolsFiltersReducer,
  showDocFinderBookmarks,
  showDocFinderFilter,
  createGroupReducer,
  fetchGroupsReducer,
  removeGroupReducer,
];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  return reducers.reduce((s, r) => r(s, action), newState);
}
