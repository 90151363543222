import React, { useCallback, useEffect, useRef, useState } from 'react';
import './UploadFileContent.scss';
import { Alert, Button, IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import api from 'common/api';

import { selectCurrentCaseId } from 'common/selectors';
import { useSelector } from 'react-redux';
import { BlobServiceClient } from '@azure/storage-blob';
import MuiButton from '@mui/material/Button';
import { Spinner } from 'features/common';
import { CircularProgressWithLabel } from './CustomProgress';
import { BatchUploadReport, FileMetaData, FolderNode, SasResponse } from './types';
import { v4 as uuidv4 } from 'uuid';
import { Accordion, Card } from 'react-bootstrap';
import {
  faCheckCircle,
  faChevronDown,
  faTimesCircle,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { validFileExts, validFileExtsShareOnly } from './constants';
import T from 'i18n';
import { useDropzone } from 'react-dropzone';
import { set } from 'date-fns';

type UploadFileContentProps = {
  fileMetadata: FileMetaData[];
  setFileMetadata: React.Dispatch<React.SetStateAction<FileMetaData[]>>;
  currentFolderId: string;
  showSidebar: boolean;
  uploadComplete: boolean;
  setUploadComplete: React.Dispatch<React.SetStateAction<boolean>>;
};

const UploadFileContent = ({
  fileMetadata,
  setFileMetadata,
  currentFolderId,
  showSidebar,
  uploadComplete,
  setUploadComplete,
}: UploadFileContentProps) => {
  const dragCounter = useRef(0);
  const caseId = useSelector(selectCurrentCaseId);
  const [showOverlay, setShowOverlay] = useState(false);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [containsValidFiles, setContainsValidFiles] = useState(true);
  const [progressState, setProgressState] = useState<{ [key: string]: number }>({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [folderToDelete, setFolderToDelete] = useState<string | null>(null);
  const [expandedFolders, setExpandedFolders] = useState<{ [key: string]: boolean }>({});
  const [processingFiles, setProcessingFiles] = useState(false);
  const [apiError, setApiError] = useState<string | null>(null);
  const [currentBatchId, setCurrentBatchId] = useState<string | null>(null);

  const processFiles = async (files: File[], folderPath: string) => {
    setFileMetadata(prevFiles => {
      const newFiles = files
        .filter(file => {
          return !prevFiles.some(f => f.name === file.name);
        })
        .map(file => {
          const extension = file.name
            .split('.')
            .pop()
            ?.toLowerCase();
          const isValid =
            extension &&
            (validFileExts.includes(extension) || validFileExtsShareOnly.includes(extension));

          const status = isValid ? '' : 'unsupported';
          const newId = uuidv4();
          const fileNameWithExtension = `${newId}.${extension}`;

          // Update progress state
          setProgressState(prevState => ({
            ...prevState,
            [newId]: 0,
          }));

          return {
            file,
            name: file.name,
            uniqueId: newId,
            fileNameWithExtension: fileNameWithExtension,
            size: file.size,
            type: file.type,
            folderPath,
            progress: 0,
            status,
          };
        });
      return [...prevFiles, ...newFiles];
    });
  };

  const onDrop = async (acceptedFiles: File[]) => {
    if (uploadComplete) {
      handleClearAll();
    }

    setProcessingFiles(true);

    const folderMap: { [folderPath: string]: File[] } = {};

    acceptedFiles.forEach(file => {
      const filePath = (file as any).path || file.name;
      const pathParts = filePath.split('/'); // we could add .slice(2) here but it's problematic with js runtimes
      const modifiedPathParts = pathParts.slice(2); // Remove first folder
      if (modifiedPathParts.length > 1) {
        const folderPath = modifiedPathParts.slice(0, -1).join('/');
        if (!folderMap[folderPath]) {
          folderMap[folderPath] = [];
        }
        folderMap[folderPath].push(file);
      } else {
        if (!folderMap['root']) {
          folderMap['root'] = [];
        }
        folderMap['root'].push(file);
      }
    });

    for (const [folderPath, files] of Object.entries(folderMap)) {
      await processFiles(files, folderPath === 'root' ? '' : folderPath);
    }

    setProcessingFiles(false);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    if (uploadComplete) {
      handleClearAll();
    }
    e.preventDefault();
    e.stopPropagation();
    setShowOverlay(false);

    setProcessingFiles(true);

    const files = e.dataTransfer.files;
    if (files && files.length > 0) {
      const items = e.dataTransfer.items;

      for (let i = 0; i < items.length; i++) {
        const entry = items[i].webkitGetAsEntry();
        if (!entry) {
          console.warn('webkitGetAsEntry is not supported, processing files directly');
          await processFiles(Array.from(files), '');
          setProcessingFiles(false);
          return;
        }

        if (entry) {
          if (entry.isFile) {
            const file = files[i];
            await processFiles([file], '');
          } else if (entry.isDirectory) {
            await processDirectory(entry as FileSystemDirectoryEntry, '');
          }
        }
      }
    }

    setProcessingFiles(false);
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      dragCounter.current += 1;
      if (dragCounter.current === 1) {
        setShowOverlay(true);
      }
    }
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    dragCounter.current -= 1;
    if (dragCounter.current === 0) {
      setShowOverlay(false);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleToggleAccordion = (folder: string) => {
    setExpandedFolders(prevState => ({
      ...prevState,
      [folder]: !prevState[folder],
    }));
  };

  const handleOpenSnackbar = (folder: string) => {
    setFolderToDelete(folder);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = useCallback(() => {
    setOpenSnackbar(false);
    setFolderToDelete(null);
  }, []);

  const handleProceed = useCallback(() => {
    if (folderToDelete) {
      setFileMetadata(prevFiles => prevFiles.filter(file => file.folderPath !== folderToDelete));
    }
    handleCloseSnackbar();
  }, [folderToDelete, setFileMetadata, handleCloseSnackbar]);

  const handleConfirm = async () => {
    try {
      setApiError(null);
      setUploadInProgress(true);

      const response = (await api.get(`/cases/${caseId}/batch-token`)) as SasResponse;
      const sasUrl = response?.sasToken;
      const containerName = response?.container;

      if (!sasUrl || !containerName) {
        setUploadInProgress(false);
        setApiError(T.translate('case.batchUpload.apiError'));
        throw new Error('SAS URL or container name is not available');
      }

      const blobServiceClient = new BlobServiceClient(sasUrl);
      const containerClient = blobServiceClient.getContainerClient('');

      const fileMetadataCopy = [...fileMetadata].filter(file => file.status !== 'unsupported');

      const uploadPromises = fileMetadataCopy.map(async (fileMeta, index) => {
        const abortController = new AbortController();
        fileMetadataCopy[index].abortController = abortController;

        setFileMetadata([...fileMetadataCopy]);

        fileMetadataCopy[index].status = 'uploading';

        const blockBlobClient = containerClient.getBlockBlobClient(fileMeta.fileNameWithExtension);
        try {
          await blockBlobClient.uploadData(fileMeta.file, {
            blobHTTPHeaders: {
              blobContentType: fileMeta.type,
            },
            onProgress: progress => {
              const percentCompleted = Math.round((progress.loadedBytes / fileMeta.size) * 100);
              setProgressState(prevState => ({
                ...prevState,
                [fileMeta.uniqueId]: percentCompleted,
              }));
            },
            abortSignal: abortController.signal,
            maxSingleShotSize: 4 * 1024 * 1024,
          });

          fileMetadataCopy[index].status = 'uploaded';
        } catch (uploadError) {
          if ((uploadError as Error).name === 'AbortError') {
            fileMetadataCopy[index].status = 'cancelled';
          } else {
            fileMetadataCopy[index].status = 'failed';
          }
        }
      });

      await Promise.all(uploadPromises);
      setFileMetadata([...fileMetadataCopy]);

      const jsonDataPayload = fileMetadataCopy
        .filter(fileMeta => fileMeta.status === 'uploaded')
        .map(fileMeta => ({
          uniqueId: fileMeta.uniqueId,
          fileName: fileMeta.name,
          folderPath: fileMeta?.folderPath,
        }));

      const fullJsonObject = {
        folderId: currentFolderId,
        data: jsonDataPayload,
      };

      if (jsonDataPayload.length > 0) {
        try {
          const createBatchTrigger = (await api.post(`/cases/${caseId}/batch`, fullJsonObject)) as {
            id: number;
          };
          if (createBatchTrigger) {
            try {
              await api.put(`/cases/${caseId}/batch/${String(createBatchTrigger.id)}`);
              setCurrentBatchId(String(createBatchTrigger.id));
              setUploadComplete(true);
            } catch (error) {
              setUploadInProgress(false);
              setApiError(T.translate('case.batchUpload.apiError'));
            }
          }
        } catch (error) {
          setUploadInProgress(false);
          setApiError(T.translate('case.batchUpload.apiError'));
        }
      } else {
        console.warn('No valid files to upload');
      }

      setUploadInProgress(false);
    } catch (e) {
      setUploadInProgress(false);
    }
  };

  const handleClearAll = useCallback(() => {
    setCurrentBatchId(null);
    setApiError(null);
    setOpenSnackbar(false);
    setUploadComplete(false);
    setFileMetadata([]);
  }, [setOpenSnackbar, setUploadComplete, setFileMetadata]);

  const processDirectory = async (directoryEntry: FileSystemDirectoryEntry, folderPath: string) => {
    const reader = directoryEntry.createReader();
    const entries = await new Promise<FileSystemEntry[]>((resolve, reject) => {
      reader.readEntries(resolve, reject);
    });

    for (const entry of entries) {
      if (entry.isFile) {
        const fileEntry = entry as FileSystemFileEntry;
        const file = await new Promise<File>((resolve, reject) => fileEntry.file(resolve, reject));
        await processFiles([file], folderPath);
      } else if (entry.isDirectory) {
        const dirEntry = entry as FileSystemDirectoryEntry;
        const newFolderPath = folderPath ? `${folderPath}/${entry.name}` : entry.name;
        await processDirectory(dirEntry, newFolderPath);
      }
    }
  };

  const removeFile = (fileName: string) => {
    setFileMetadata(prevFiles => prevFiles.filter(file => file.name !== fileName));
  };

  const renderFilesInFolder = (files: FileMetaData[]) => {
    const statusOrder: any = {
      unsupported: 0,
      failed: 1,
      cancelled: 2,
      uploading: 3,
      '': 4,
      uploaded: 5,
    };
    const sortedFiles = [...files].sort((a, b) => {
      const aStatus = statusOrder[a.status] ?? 6;
      const bStatus = statusOrder[b.status] ?? 6;
      return aStatus - bStatus;
    });

    return sortedFiles.map(file => {
      const progressValue = progressState[file.uniqueId] || 0;

      return (
        <li key={file.uniqueId}>
          <span>{file.name}</span>
          <span>{(file.size / (1024 * 1024)).toFixed(2)} MB</span>

          {file?.status === 'uploading' ? (
            <CircularProgressWithLabel value={progressValue} size={28} />
          ) : file?.status === 'unsupported' ? (
            <span style={{ color: 'red' }}>
              {T.translate('case.batchUpload.fileLabels.unsupported')}
            </span>
          ) : file?.status === 'cancelled' ? (
            <span style={{ color: 'orange' }}>
              {T.translate('case.batchUpload.fileLabels.cancelled')}
            </span>
          ) : file?.status === 'uploaded' ? (
            <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green', marginLeft: '8px' }} />
          ) : file?.status === 'failed' ? (
            <FontAwesomeIcon icon={faTimesCircle} style={{ color: 'red', marginLeft: '8px' }} />
          ) : null}

          {file?.status === 'uploading' && progressValue < 100 ? (
            <IconButton
              onClick={() => {
                if (file.abortController) {
                  file.abortController.abort();
                  setFileMetadata(prevFiles =>
                    prevFiles.map(f =>
                      f.uniqueId === file.uniqueId ? { ...f, status: 'cancelled' } : f,
                    ),
                  );
                }
              }}
              style={{ right: 0, top: 0 }}
            >
              <CloseIcon style={{ height: 12, width: 12 }} />
            </IconButton>
          ) : file?.status !== 'uploaded' && file?.status !== 'cancelled' ? (
            <IconButton onClick={() => removeFile(file.name)} style={{ right: 0, top: 0 }}>
              <CloseIcon style={{ height: 12, width: 12 }} />
            </IconButton>
          ) : null}
        </li>
      );
    });
  };

  const buildFolderTree = (files: FileMetaData[]) => {
    const root: FolderNode = { name: 'root', path: '', files: [], subfolders: {} };

    files.forEach(file => {
      const pathParts = file.folderPath ? file.folderPath.split('/') : [];
      let currentNode = root;
      for (const part of pathParts) {
        if (!currentNode.subfolders[part]) {
          const newPath = currentNode.path ? `${currentNode.path}/${part}` : part;
          currentNode.subfolders[part] = { name: part, path: newPath, files: [], subfolders: {} };
        }
        currentNode = currentNode.subfolders[part];
      }
      currentNode.files.push(file);
    });

    return root;
  };

  const flattenFolderTree = (root: FolderNode) => {
    const result: { node: FolderNode; depth: number }[] = [];
    const stack: { node: FolderNode; depth: number }[] = [{ node: root, depth: 0 }];

    while (stack.length > 0) {
      const { node, depth } = stack.pop()!;
      result.push({ node, depth });

      const subfolderNames = Object.keys(node.subfolders)
        .sort()
        .reverse();
      subfolderNames.forEach(subfolderName => {
        stack.push({ node: node.subfolders[subfolderName], depth: depth + 1 });
      });
    }

    return result;
  };

  useEffect(() => {
    const validFiles = fileMetadata.filter(
      fileMeta => fileMeta.status !== 'unsupported' && fileMeta.status !== 'failed',
    );

    setContainsValidFiles(validFiles.length > 0);
  }, [fileMetadata]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleProceed();
      }
    };

    if (openSnackbar) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [openSnackbar, handleProceed]);

  useEffect(() => {
    if (uploadComplete && !showSidebar) {
      handleClearAll();
    }
  }, [handleClearAll, showSidebar, uploadComplete]);

  const folderTree = buildFolderTree(fileMetadata);
  const flattenedFolders = flattenFolderTree(folderTree);
  const generateCsv = (report: BatchUploadReport) => {
    if (!report) return '';

    const headers = [
      'id',
      'success',
      'failed',
      'duplicates',
      'processing',
      'file_id',
      'file_sequence',
      'file_isDuplicate',
      'file_state',
      'file_fileId',
    ];

    const rows = report.files.map(file => [
      report.id,
      report.success,
      report.failed,
      report.duplicates,
      report.processing,
      file.id,
      file.sequence,
      file.isDuplicate ? 'Yes' : 'No',
      file.state,
      file.fileId,
    ]);

    const csvContent = [headers.join(','), ...rows.map(row => row.join(','))].join('\n');

    return csvContent;
  };

  const handleGetReport = async () => {
    try {
      const report = await api.get(`cases/${caseId}/batch/${String(currentBatchId)}`);
      generateCsv(report as BatchUploadReport);
    } catch (error) {
      setUploadInProgress(false);
      setApiError(T.translate('case.batchUpload.reportError'));
    }
  };

  return (
    <>
      {showOverlay && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 9999,
            backgroundColor: 'transparent',
          }}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        />
      )}
      <div className="upload-blob-container">
        <div {...getRootProps({ className: `drag-drop-box ${isDragActive ? 'drag-active' : ''}` })}>
          <input {...getInputProps()} />
          <p>{T.translate('case.batchUpload.dropzone.title')}</p>
          <MuiButton variant="text" sx={{ paddingLeft: 0, justifyContent: 'start' }} size="small">
            <p>{T.translate('case.batchUpload.dropzone.subtitle')}</p>
          </MuiButton>
        </div>
      </div>
      <div className="upload-file-list">
        <p
          style={{
            color: 'red',
            textAlign: 'center',
            margin: '10px 0',
            display: 'block',
          }}
        >
          {apiError}
        </p>
        {fileMetadata?.length > 0 && !processingFiles && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginLeft: 4,
              alignItems: 'center',
            }}
          >
            {uploadComplete ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 4,
                  justifyContent: 'flex-start',
                  flexDirection: 'row',
                  alignContent: 'center',
                }}
              >
                <h5>{T.translate('case.batchUpload.uploadComplete')}</h5>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  style={{ color: 'green', paddingBottom: 4 }}
                  size={'2x'}
                />

                {/* 
                TODO: Implement this later on
                {currentBatchId && (
                  <MuiButton
                    variant="text"
                    sx={{ paddingLeft: 0, justifyContent: 'start' }}
                    size="small"
                    onClick={handleGetReport}
                  >
                    {T.translate('case.batchUpload.getReport')}
                  </MuiButton>
                )} */}
              </div>
            ) : (
              <h5>{T.translate('case.batchUpload.dropzone.documents')}</h5>
            )}
            <div>
              {containsValidFiles && !uploadComplete ? (
                <>
                  {!uploadInProgress && !uploadComplete && (
                    <IconButton
                      onClick={handleClearAll}
                      sx={{
                        color: '#fa4e4b',
                        '&:hover': {
                          backgroundColor: 'rgba(250, 78, 75, 0.1)',
                        },
                      }}
                    >
                      <FontAwesomeIcon size="sm" icon={faTrash} />
                    </IconButton>
                  )}
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={async () => await handleConfirm()}
                    disabled={uploadInProgress}
                    style={{ marginLeft: 5 }}
                  >
                    {uploadInProgress ? (
                      <Spinner style={{ fontSize: '0.5rem' }} />
                    ) : (
                      T.translate('case.batchUpload.dropzone.upload')
                    )}
                  </Button>
                </>
              ) : (
                <IconButton
                  onClick={handleClearAll}
                  sx={{
                    color: '#fa4e4b',
                    '&:hover': {
                      backgroundColor: 'rgba(250, 78, 75, 0.1)',
                    },
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} size="1x" />
                </IconButton>
              )}
            </div>
          </div>
        )}
        {processingFiles ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 10,
              flexDirection: 'column',
              gap: 10,
            }}
          >
            <Spinner />
            <p
              style={{
                textAlign: 'center',
              }}
            >
              {T.translate('case.batchUpload.processingFiles', { fileCount: fileMetadata?.length })}
            </p>
          </div>
        ) : (
          flattenedFolders.map(({ node, depth }) => (
            <div key={node.path} style={{ marginLeft: depth * 20 }}>
              {node.name !== 'root' && (
                <Accordion>
                  <Card
                    style={{
                      margin: 3,
                      border: 'none',
                      boxShadow: 'none',
                      backgroundColor: 'transparent',
                    }}
                  >
                    <Accordion.Toggle
                      as={Button}
                      eventKey={node.path}
                      onClick={() => handleToggleAccordion(node.path)}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        backgroundColor: 'transparent',
                        flexDirection: 'row',
                        gap: 10,
                        color: 'black',
                      }}
                    >
                      <FontAwesomeIcon icon={faChevronDown} />
                      {T.translate('case.batchUpload.folderHeader', { folderName: node.name })}
                      {!uploadInProgress ? (
                        <IconButton
                          onClick={() => handleOpenSnackbar(node.path)}
                          style={{ right: 0, marginLeft: 'auto' }}
                        >
                          <CloseIcon style={{ height: 12, width: 12 }} />
                        </IconButton>
                      ) : (
                        <IconButton
                          onClick={() => {}}
                          style={{ right: 0, marginLeft: 'auto' }}
                          disabled
                        >
                          <CloseIcon style={{ height: 12, width: 12, color: 'white' }} />
                        </IconButton>
                      )}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={node.path} in={expandedFolders[node.path]}>
                      <Card.Body>
                        <ul>{renderFilesInFolder(node.files)}</ul>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              )}

              {/* Render files in the root folder */}
              {node.name === 'root' && node.files.length > 0 && (
                <ul>{renderFilesInFolder(node.files)}</ul>
              )}
            </div>
          ))
        )}
      </div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={20000}
        onClose={(event, reason) => {
          if (reason === 'clickaway') {
            return;
          }
          handleCloseSnackbar();
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="warning"
          variant="filled"
          sx={{ backgroundColor: '#7CA0F4', color: 'white' }}
          action={
            <>
              <Button color="inherit" size="small" onClick={handleProceed}>
                {T.translate('case.batchUpload.snackbar.proceed')}
              </Button>
              <Button color="inherit" size="small" onClick={handleCloseSnackbar}>
                {T.translate('case.batchUpload.snackbar.cancel')}
              </Button>
            </>
          }
        >
          {T.translate('case.batchUpload.snackbar.warning', { folderToDelete })}
        </Alert>
      </Snackbar>
    </>
  );
};

export default UploadFileContent;
