import React from 'react';
import T from 'i18n';
import { Button, ListItem, Menu, Typography } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SearchInputLocations from './SearchInputLocations';
import { isMobile } from 'utils/device';
import DocumentsList from './DocumentsList';
import { removeIndexFolders } from 'utils/foldersStructuring';
import { useSelector } from 'react-redux';
import { selectFolders } from 'common/selectors';

interface TrialBundlesMenuProps {
  filters: any;
  handleChangeFilters: Function;
  filtersMenu: any;
  openFiltersMenu: boolean;
  setFiltersMenu: Function;
  containsMatches?: boolean;
}

const TrialBundlesMenu: React.FC<TrialBundlesMenuProps> = ({
  filters,
  handleChangeFilters,
  filtersMenu,
  openFiltersMenu,
  setFiltersMenu,
  containsMatches,
}) => {
  const folders = useSelector(selectFolders);
  const trialBundlesWithoutIndexFolder = removeIndexFolders(folders['trialbooks']);
  const addTrialbooksList =
    trialBundlesWithoutIndexFolder && trialBundlesWithoutIndexFolder.length > 0;
  const addTeamBundlesList = folders && folders['teamBundles'] && folders['teamBundles'].length > 0;
  const addPrivateBundlesList =
    folders && folders['privateBundles'] && folders['privateBundles'].length > 0;

  const locationsList = [
    addTrialbooksList && { id: 'trialBundle', name: 'Trial Bundles' },
    ...(addTrialbooksList ? trialBundlesWithoutIndexFolder : []),
    addTeamBundlesList && { id: 'teamBundles', name: 'Team Bundles' },
    ...(addTeamBundlesList ? folders['teamBundles'] : []),
    addPrivateBundlesList && { id: 'privateBundles', name: 'Private Bundles' },
    ...(addPrivateBundlesList ? folders['privateBundles'] : []),
  ].filter(Boolean);

  const filtersScrollbarRef = React.useRef<any>(null);

  return (
    <Menu
      anchorEl={filtersMenu}
      open={openFiltersMenu}
      onClose={() => setFiltersMenu(null)}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      sx={{ maxWidth: isMobile ? '90vw' : '60vw' }}
    >
      <ListItem sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography sx={{ color: 'GrayText' }}>Locations</Typography>
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            handleChangeFilters({
              location: [],
              files: [],
            });
          }}
        >
          {T.translate('common.clear')}
        </Button>
      </ListItem>
      <PerfectScrollbar
        style={{ maxHeight: '70vh' }}
        containerRef={ref => (filtersScrollbarRef.current = ref)}
      >
        {!containsMatches && filters.files?.length > 0 && (
          <DocumentsList filters={filters} handleChangeFilters={handleChangeFilters} />
        )}
        <SearchInputLocations
          locationsMenu={locationsList}
          filters={filters}
          handleChangeFilters={handleChangeFilters}
          isAnswersMode={false}
          filtersScrollbarRef={filtersScrollbarRef}
        />
      </PerfectScrollbar>
    </Menu>
  );
};

export default TrialBundlesMenu;
