import {
  CASE_FETCH_NOTIFICATIONS_BEGIN,
  CASE_FETCH_NOTIFICATIONS_SUCCESS,
  CASE_FETCH_NOTIFICATIONS_FAILURE,
  CASE_FETCH_NOTIFICATIONS_DISMISS_FEEDBACK,
  CASE_FETCH_SIGNALR_NOTIFICATION_SUCCESS,
  CASE_FETCH_SIGNALR_NOTIFICATION_FEEDBACK,
} from './constants';
import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';

export const fetchNotifications = createPromiseAction(CASE_FETCH_NOTIFICATIONS_BEGIN);

export function dismissFetchNotificationsFeedback() {
  return {
    type: CASE_FETCH_NOTIFICATIONS_DISMISS_FEEDBACK,
  };
}

export function* doFetchNotifications(action) {
  const {
    payload: { caseId },
  } = action;

  let res = yield call(api.get, `/cases/${caseId}/notifications`);

  if (res && res.error) {
    yield put({
      type: CASE_FETCH_NOTIFICATIONS_FAILURE,
      feedback: {
        message: 'feedback.fetchNotificationsFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_FETCH_NOTIFICATIONS_SUCCESS,
    data: { notifications: res },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchFetchNotifications() {
  yield takeLatest(fetchNotifications, withCurrentCaseId(doFetchNotifications));
}

export function useFetchNotifications() {
  const dispatch = useDispatch();
  const { notifications, fetchNotificationsPending, fetchNotificationsFeedback } = useSelector(
    state => ({
      notifications: state.case.notifications,
      fetchNotificationsPending: state.case.fetchNotificationsPending,
      fetchNotificationsFeedback: state.case.fetchNotificationsFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(fetchNotifications(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissFetchNotificationsFeedback());
  }, [dispatch]);

  return {
    notifications,
    fetchNotifications: boundAction,
    fetchNotificationsPending,
    fetchNotificationsFeedback,
    dismissFetchNotificationsFeedback: boundDismissFeedback,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CASE_FETCH_NOTIFICATIONS_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        fetchNotificationsPending: true,
        fetchNotificationsFeedback: null,
      };

    case CASE_FETCH_NOTIFICATIONS_SUCCESS:
      // The request is success
      return {
        ...state,
        fetchNotificationsPending: false,
        fetchNotificationsFeedback: action.feedback,
        notifications: action.data.notifications,
      };

    case CASE_FETCH_SIGNALR_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: [...state.notifications, action.data.notification],
        fetchNotificationsFeedback: action.feedback,
      };

    case CASE_FETCH_SIGNALR_NOTIFICATION_FEEDBACK:
      return {
        ...state,
        fetchNotificationsFeedback: action.feedback,
      };

    case CASE_FETCH_NOTIFICATIONS_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchNotificationsPending: false,
        fetchNotificationsFeedback: action.feedback,
      };

    case CASE_FETCH_NOTIFICATIONS_DISMISS_FEEDBACK:
      // Dismiss the request failure Feedback
      return {
        ...state,
        fetchNotificationsFeedback: null,
      };

    default:
      return state;
  }
}
