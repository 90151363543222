import React, { useEffect, useState } from 'react';
import T from 'i18n';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faKey,
  faUserLock,
  faStickyNote,
  faLink,
  faInfoCircle,
  faLayerGroup,
  faClone,
  faCircle,
  faRetweet,
  faFlag,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InfoTwoRows from 'features/common/InfoTwoRows';
import PopoverStickOnHover from 'features/common/PopoverStickOnHover';
import Popper from '@mui/material/Popper';
import { FileDetails } from '../FileDetails';
import { BundleLocations } from '../Viewer';
import { FileAttachments } from '..';
import { ClickAwayListener } from '@mui/material';
import FilterProvider from './FilterProvider';
import { confidentialityRingCategoryLabel } from '../enums';
import { useSelector } from 'react-redux';
import { selectConfidentialityRing, selectCurrentUserConfidentialityRing } from 'common/selectors';
import { AttachEmail, Attachment } from '@mui/icons-material';

export const cfRingColorMapping = {
  noRing: 'rgb(67 67 219)',
  outerRing: 'rgb(76, 175, 80)',
  innerRing: 'red',
} as any;

export default ({ row, customColumns, filter, present, isPresentModePage }: any) => {
  const currrentUserCR = useSelector(selectCurrentUserConfidentialityRing);
  const isConfidentialityRing =
    useSelector(selectConfidentialityRing) && currrentUserCR !== 'noRing';
  const [showAttachments, setShowAttachments] = useState(false);
  const [attachmentPopperPosition, setAttachmentPopperPosition] = useState(null);
  const {
    id,
    notes,
    assignedTo,
    hasAnnotations,
    hasPublicHyperlinks,
    hasCrossRefs,
    source,
    duplicate,
    locations,
    attachments,
    isAttachedTo,
    pageCount,
    confidentialityRing,
    flags,
  } = row;
  const [showMoreInfoIcon, setShowMoreInfoIcon] = useState(false);

  const isPrivate = !(
    assignedTo &&
    Array.isArray(assignedTo) &&
    (assignedTo.includes('trialbooks') ||
      assignedTo.includes('my-court-bundles') ||
      assignedTo.includes('other-court-bundles'))
  );

  const handleAttachmentIconClick = (e: any) => {
    const outsideElem = document.getElementsByClassName('document-count');
    outsideElem && outsideElem.length > 0 && ((outsideElem[0] as unknown) as any).click(); // added bcoz clickAwayListener is not getting triggered when clicking on another attachment icon
    e.preventDefault();
    e.stopPropagation();
    setShowAttachments(true);
    setAttachmentPopperPosition(e.currentTarget);
  };

  const handleClickAway = () => {
    setShowAttachments(false);
    setAttachmentPopperPosition(null);
  };

  useEffect(() => {
    if (filter) {
      const removeColumns = ['tab', 'index', 'info'];
      const columns = customColumns
        .filter((column: any) => !removeColumns.includes(column))
        .map((col: any) =>
          col === 'docDate'
            ? 'docDate'
            : col === 'pageCount'
            ? 'pageCount'
            : col === 'fileType'
            ? 'type'
            : col,
        );
      const matchedData = columns.filter(
        (col: any) =>
          row[col] &&
          (col === 'docDate' ? moment(row[col], 'DD/MM/YYYY').format('DD/MM/YYYY') : row[col])
            .toString()
            .toLowerCase()
            .includes(filter.toLowerCase()),
      );
      if (matchedData.length > 0) setShowMoreInfoIcon(false);
      else setShowMoreInfoIcon(true);
    }
  }, [filter, customColumns, row]);

  return (
    <Row style={{ width: '100%', marginRight: '-2px', marginLeft: '-2px' }}>
      {isConfidentialityRing && confidentialityRing && (
        <Col
          style={{
            paddingRight: '2px',
            paddingLeft: '2px',
            marginRight: '0.5rem',
            flexGrow: 0,
          }}
        >
          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Popover id="confidentialityRing" style={{ maxWidth: '350px', fontSize: '0.75rem' }}>
                <React.Fragment>
                  <Popover.Content>
                    {(confidentialityRingCategoryLabel as any)[confidentialityRing]}
                  </Popover.Content>
                </React.Fragment>
              </Popover>
            }
          >
            <FontAwesomeIcon
              style={{ color: cfRingColorMapping[confidentialityRing] }}
              icon={faCircle}
            />
          </OverlayTrigger>
        </Col>
      )}
      {locations && locations.length > 0 && (
        <Col
          style={{
            paddingRight: '2px',
            paddingLeft: '2px',
            marginRight: '0.5rem',
            flexGrow: 0,
          }}
        >
          <div style={{ height: '12px', width: '12px' }}>
            <BundleLocations
              filePageCount={pageCount}
              bundleLocations={[]}
              fileId={id}
              filterVal={filter}
              isGrid={true}
            />
          </div>
        </Col>
      )}
      {attachments && attachments.length > 0 && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Col
            style={{
              paddingRight: '2px',
              paddingLeft: '2px',
              marginRight: '0.5rem',
              flexGrow: 0,
            }}
          >
            <div className="attachmentIcon" onClick={handleAttachmentIconClick}>
              <AttachEmail sx={{ '&:hover': { cursor: 'pointer' } }} fontSize="small" />
              <Popper
                id={`simple-attachment-popover-${id}`}
                open={Boolean(showAttachments)}
                anchorEl={attachmentPopperPosition}
                disablePortal={true}
                placement="bottom-end"
                style={{ zIndex: 5 }}
              >
                <FileAttachments
                  attachments={attachments}
                  handleClickAway={handleClickAway}
                  parentFileId={id}
                  present={present}
                />
              </Popper>
            </div>
          </Col>
        </ClickAwayListener>
      )}
      {isAttachedTo && isAttachedTo.length > 0 && (
        <Col
          style={{
            paddingRight: '2px',
            paddingLeft: '2px',
            marginRight: '0.5rem',
            flexGrow: 0,
          }}
        >
          <Attachment sx={{ color: '#6c757c' }} />
        </Col>
      )}
      {showMoreInfoIcon && !isPresentModePage && (
        <Col
          style={{
            paddingRight: '2px',
            paddingLeft: '2px',
            marginRight: '0.5rem',
            flexGrow: 0,
          }}
        >
          <PopoverStickOnHover
            component={
              <div>
                <Popover.Title as="h4">{T.translate('generic.public')}</Popover.Title>
                <Popover.Content>
                  <FilterProvider filter={filter}>
                    <FileDetails row={row} columns={customColumns} DefaultShowMoreInfo={false} />
                  </FilterProvider>
                </Popover.Content>
              </div>
            }
            placement="left"
            onMouseEnter={() => {}}
            delay={{ show: 250, hide: 400 }}
            style={{ width: '350px', maxWidth: '350px', fontSize: '0.75rem' }}
          >
            <div style={{ height: '12px', width: '12px' }}>
              <FontAwesomeIcon style={{ color: '#7BA0F4' }} icon={faInfoCircle} />
            </div>
          </PopoverStickOnHover>
        </Col>
      )}
      {notes && (
        <Col
          style={{
            paddingRight: '2px',
            paddingLeft: '2px',
            marginRight: '0.5rem',
            flexGrow: 0,
          }}
        >
          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Popover
                id="notes"
                style={{ width: '350px', maxWidth: '350px', fontSize: '0.75rem' }}
              >
                <React.Fragment>
                  <Popover.Title as="h4">{T.translate('case.privateToTeam')}</Popover.Title>
                  <Popover.Content>
                    {notes && (
                      <FilterProvider filter={filter}>
                        <InfoTwoRows field={notes} title={'case.notes'} />
                      </FilterProvider>
                    )}
                  </Popover.Content>
                </React.Fragment>
              </Popover>
            }
          >
            <FontAwesomeIcon
              style={{ color: 'yellow', stroke: 'black', strokeWidth: '10' }}
              icon={faKey}
            />
          </OverlayTrigger>
        </Col>
      )}
      {isPrivate && (
        <Col
          style={{
            paddingRight: '2px',
            paddingLeft: '2px',
            marginRight: '0.5rem',
            flexGrow: 0,
          }}
        >
          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Popover id="private" style={{ maxWidth: '350px', fontSize: '0.75rem' }}>
                <React.Fragment>
                  <Popover.Content>{T.translate('case.isPrivateDocument')}</Popover.Content>
                </React.Fragment>
              </Popover>
            }
          >
            <FontAwesomeIcon style={{ color: '#6c757c' }} icon={faUserLock} />
          </OverlayTrigger>
        </Col>
      )}
      {hasAnnotations && (
        <Col
          style={{
            paddingRight: '2px',
            paddingLeft: '2px',
            marginRight: '0.5rem',
            flexGrow: 0,
          }}
        >
          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Popover id="annotations" style={{ maxWidth: '350px', fontSize: '0.75rem' }}>
                <React.Fragment>
                  <Popover.Content>
                    {T.translate('case.documentContainsAnnotations')}
                  </Popover.Content>
                </React.Fragment>
              </Popover>
            }
          >
            <FontAwesomeIcon style={{ color: 'rgb(213, 114, 0)' }} icon={faStickyNote} />
          </OverlayTrigger>
        </Col>
      )}
      {hasPublicHyperlinks && (
        <Col
          style={{
            paddingRight: '2px',
            paddingLeft: '2px',
            marginRight: '0.5rem',
            flexGrow: 0,
          }}
        >
          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Popover id="publicHyperlinks" style={{ maxWidth: '350px', fontSize: '0.75rem' }}>
                <React.Fragment>
                  <Popover.Content>
                    {T.translate('case.documentContainsPublicHyperlinks')}
                  </Popover.Content>
                </React.Fragment>
              </Popover>
            }
          >
            <FontAwesomeIcon style={{ color: '#0288d1' }} icon={faLink} />
          </OverlayTrigger>
        </Col>
      )}
      {hasCrossRefs && (
        <Col
          style={{
            paddingRight: '2px',
            paddingLeft: '2px',
            marginRight: '0.5rem',
            flexGrow: 0,
          }}
        >
          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Popover id="hasCrossrefs" style={{ maxWidth: '350px', fontSize: '0.75rem' }}>
                <React.Fragment>
                  <Popover.Content>{T.translate('case.documentContainsCrossRefs')}</Popover.Content>
                </React.Fragment>
              </Popover>
            }
          >
            <FontAwesomeIcon style={{ color: '#0288d1' }} icon={faRetweet} />
          </OverlayTrigger>
        </Col>
      )}
      {source === 'merge' && (
        <Col
          style={{
            paddingRight: '2px',
            paddingLeft: '2px',
            marginRight: '0.5rem',
            flexGrow: 0,
          }}
        >
          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Popover id="merge" style={{ maxWidth: '350px', fontSize: '0.75rem' }}>
                <React.Fragment>
                  <Popover.Content>{T.translate('case.isMergedDoc')}</Popover.Content>
                </React.Fragment>
              </Popover>
            }
          >
            <FontAwesomeIcon style={{ color: '#6c757c' }} icon={faLayerGroup} />
          </OverlayTrigger>
        </Col>
      )}
      {duplicate && (
        <Col
          style={{
            paddingRight: '2px',
            paddingLeft: '2px',
            marginRight: '0.5rem',
            flexGrow: 0,
          }}
        >
          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Popover id="duplicate" style={{ maxWidth: '350px', fontSize: '0.75rem' }}>
                <React.Fragment>
                  <Popover.Content>{T.translate('case.duplicateFileMsg')}</Popover.Content>
                </React.Fragment>
              </Popover>
            }
          >
            <FontAwesomeIcon style={{ color: '#6c757c' }} icon={faClone} />
          </OverlayTrigger>
        </Col>
      )}
      {flags && flags.length > 0 && (
        <Col
          style={{
            paddingRight: '2px',
            paddingLeft: '2px',
            marginRight: '0.5rem',
            flexGrow: 0,
          }}
        >
          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Popover id="duplicate" style={{ maxWidth: '350px', fontSize: '0.75rem' }}>
                <React.Fragment>
                  <Popover.Title as="h4">{T.translate('case.flags')}</Popover.Title>
                  <Popover.Content>{flags}</Popover.Content>
                </React.Fragment>
              </Popover>
            }
          >
            <FontAwesomeIcon style={{ color: 'red' }} icon={faFlag} />
          </OverlayTrigger>
        </Col>
      )}
    </Row>
  );
};
